import { store } from '../../redux/store';
import commonActions from '../../redux/common/actions';
import {
  CONTAINER_TYPES,
  DND_CAPTURE_ITEM_DROP_ON_JOTS_DOCUMENT_CREATION_CONTAINER,
  DND_CAPTURE_ITEM_DROP_ON_MY_FILES_CREATION_CONTAINER,
  DND_JOTS_ITEM_DROP_ON_JOTS_DOCUMENT_CREATION_CONTAINER,
  DND_JOTS_ITEM_DROP_ON_MY_FILES_CREATION_CREATION_CONTAINER,
  DND_JOTS_PAGE_DROP_ON_MY_FILES_CREATION_CONTAINER,
  DND_MY_FILES_ITEM_DROP_ON_JOTS_CREATION_CONTAINER,
  DND_MY_FILES_FOLDER_DROP_ON_MY_FILES_CREATION_CONTAINER,
  DND_MY_FILES_ITEM_DROP_ON_MY_FILES_CREATION_CONTAINER,
  DND_MY_FILES_PAGE_DROP_ON_MY_FILES_CREATION_CONTAINER,
} from './constant';
import {
  dndCreateModalVisibility,
  reorderHelper,
  removeItemsFromCaptureHelper,
  updateJotsFreeSpaceCoordinates,
  updateMyFilesFreeSpaceCoordinates,
  reorderDocumentItemHelper,
  removeItemsFromJotsHelper,
  addItemToMyFilesHelper,
  removeItemFromMyFilesHelper,
  addItemToJotsHelper,
  addItemToCaptureHelper,
  addItemToPage,
  alertViewHelper,
  setDroppedDataFolderId,
  updateItemSize,
} from './actionHelper';
import {
  freeSpaceViewAPIHelper,
  moveAPI,
  moveCaptureItemToOthersHelper,
  moveItemsToCapture,
  reorderItems,
} from './sagaHelper';
import { updateIndexSection } from '../boardGroups/helper';
import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
const replaceString = /_list$|_menu$/;
// Handler functions
function moveCaptureItemToJots({
  activeId,
  overContainerId,
  extractName,
  isMultipleCaptureSelect,
}) {
  const { captureLists, captureMultiSelect } = store.getState().Capture;
  const { jotsProjectItems } = store.getState().jotsReducer;
  const itemDetails = isMultipleCaptureSelect
    ? captureLists?.filter((data) => captureMultiSelect.includes(data?.id))
    : captureLists.find((data) => data.id === activeId);
  const captureIds = isMultipleCaptureSelect
    ? itemDetails.map((data) => data.id)
    : [activeId];

  // Remove Items From Capture
  removeItemsFromCaptureHelper({
    activeId,
    isMultipleCaptureSelect,
  });

  // Add Item To Jots
  addItemToJotsHelper({
    isMultipleSelectScenario: isMultipleCaptureSelect,
    itemDetails,
    overContainerId,
    jotsProjectItems,
  });

  // API Call
  moveCaptureItemToOthersHelper({
    activeIds: captureIds,
    targetId: overContainerId,
    extractName,
    overDocument: true,
    targetContainer: CONTAINER_TYPES.JOTS,
  });
}
function moveCaptureItemToJotsPage({
  activeId,
  overId,
  extractName,
  isMultipleCaptureSelect,
}) {
  const { captureMultiSelect, captureLists } = store.getState().Capture,
    captureIds = isMultipleCaptureSelect ? captureMultiSelect : [activeId];

  // Update Size
  updateItemSize({
    containerArray: captureLists,
    activeIds: captureIds,
    overId,
    overContainer: CONTAINER_TYPES.JOTS,
  });

  // Add Item to Jots Page
  addItemToPage({
    activeId,
    multiSelectArray: captureMultiSelect,
    pageId: overId,
    toJotsPage: true,
    isMultipleSelectScenario: isMultipleCaptureSelect,
    from: CONTAINER_TYPES.CAPTURE,
  });

  // Remove Item From Capture
  removeItemsFromCaptureHelper({
    activeId,
    isMultipleCaptureSelect,
  });

  // API Call
  moveCaptureItemToOthersHelper({
    activeIds: captureIds,
    targetId: overId,
    extractName,
    overDocument: true,
    targetContainer: CONTAINER_TYPES.JOTS,
  });
}
function moveCaptureItemToJotsNewPage({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'homeNewFolderVisibility',
    selectedType: 'document',
    overContainerId,
    dropAction: DND_CAPTURE_ITEM_DROP_ON_JOTS_DOCUMENT_CREATION_CONTAINER,
  });
}
function moveJotsItemToCapture({
  activeId,
  activeContainerId,
  extractName,
  isMultipleJotsItemSelect,
}) {
  const { captureLists } = store.getState().Capture;
  const { jotsProjectItems, jotsMultiSelect } = store.getState().jotsReducer;
  const multiSelectArray = jotsMultiSelect
    .filter((item) => item.objectType === 'item')
    .map((data) => data.id);

  const itemDetails = isMultipleJotsItemSelect
    ? jotsProjectItems?.filter((data) => multiSelectArray.includes(data?.id))
    : jotsProjectItems.find((data) => data.id === activeId);
  const activeIds = isMultipleJotsItemSelect ? multiSelectArray : [activeId];

  // Remove Item From Jots
  removeItemsFromJotsHelper({ activeIds, isMultipleJotsItemSelect });

  // Add Jots Item to Capture
  addItemToCaptureHelper({
    isMultipleSelectScenario: isMultipleJotsItemSelect,
    itemDetails,
    captureLists,
  });

  // API Call
  moveItemsToCapture({
    activeIds,
    parentId: activeContainerId,
    extractName,
  });
}
function moveJotsItemToJotsPage({
  activeId,
  overId,
  activeContainerId,
  extractName,
  isMultipleJotsItemSelect,
}) {
  const { jotsMultiSelect, jotsProjectItems } = store.getState().jotsReducer;
  const multiSelectArray = jotsMultiSelect
    .filter((item) => item.objectType === 'item')
    .map((data) => data.id);
  const activeIds = isMultipleJotsItemSelect ? multiSelectArray : [activeId];

  // Add Item to Jots Page
  addItemToPage({
    activeId,
    multiSelectArray,
    pageId: overId,
    toJotsPage: true,
    isMultipleSelectScenario: isMultipleJotsItemSelect,
    from: CONTAINER_TYPES.JOTS,
  });

  // Update Size
  updateItemSize({
    containerArray: jotsProjectItems,
    activeIds,
    overId,
    overContainer: CONTAINER_TYPES.JOTS,
  });

  // Remove Item From Jots
  removeItemsFromJotsHelper({ activeIds, isMultipleJotsItemSelect });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overId,
    extractName,
    overDocument: true,
    targetContainer: CONTAINER_TYPES.JOTS,
  });
}
function moveJotsItemToJotsNewPage({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'homeNewFolderVisibility',
    selectedType: 'document',
    overContainerId,
    dropAction: DND_JOTS_ITEM_DROP_ON_JOTS_DOCUMENT_CREATION_CONTAINER,
  });
}
function moveCaptureItemToMyFiles({
  activeId,
  overContainerId,
  extractName,
  overId,
  isMultipleCaptureSelect,
}) {
  const { captureLists, captureMultiSelect } = store.getState().Capture;
  const { boardDetails } = store.getState().BoardGroups;

  const itemDetails = isMultipleCaptureSelect
    ? captureLists?.filter((data) => captureMultiSelect.includes(data?.id))
    : captureLists.find((data) => data.id === activeId);
  const captureIds = isMultipleCaptureSelect
    ? itemDetails.map((data) => data.id)
    : [activeId];

  // Remove Items From Capture
  removeItemsFromCaptureHelper({
    activeId,
    isMultipleCaptureSelect,
  });

  // Add Items to MyFiles
  addItemToMyFilesHelper({
    isMultipleSelectScenario: isMultipleCaptureSelect,
    itemDetails,
    overContainerId,
    myFilesItems: boardDetails,
  });

  // API Call
  moveCaptureItemToOthersHelper({
    activeIds: captureIds,
    targetId: overContainerId,
    extractName,
    targetContainer: CONTAINER_TYPES.MY_FILES,
  });
}
function moveCaptureItemToMyFilesPage({
  activeId,
  overId,
  extractName,
  isMultipleCaptureSelect,
}) {
  const { captureMultiSelect, captureLists } = store.getState().Capture,
    captureIds = isMultipleCaptureSelect ? captureMultiSelect : [activeId];

  // Add Item to Jots Page
  addItemToPage({
    activeId,
    multiSelectArray: captureMultiSelect,
    pageId: overId,
    isMultipleSelectScenario: isMultipleCaptureSelect,
    from: CONTAINER_TYPES.CAPTURE,
  });

  // Update Size
  updateItemSize({
    containerArray: captureLists,
    activeIds: captureIds,
    overId,
    overContainer: CONTAINER_TYPES.MY_FILES,
  });

  // Remove Item From Capture
  removeItemsFromCaptureHelper({
    activeId,
    isMultipleCaptureSelect,
  });

  // API Call
  moveCaptureItemToOthersHelper({
    activeIds: captureIds,
    targetId: overId,
    extractName,
    overDocument: true,
    targetContainer: CONTAINER_TYPES.MY_FILES,
  });
}
function moveCaptureItemToMyFilesNewPage({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'folderCreateModalVisibility',
    selectedType: 'document',
    overContainerId,
    dropAction: DND_CAPTURE_ITEM_DROP_ON_MY_FILES_CREATION_CONTAINER,
  });
}
function moveCaptureItemToFolder({
  activeId,
  overId,
  extractName,
  isMultipleCaptureSelect,
  isOverIndexSection,
  targetContainer,
}) {
  const { captureMultiSelect, captureLists } = store.getState().Capture,
    captureIds = isMultipleCaptureSelect ? captureMultiSelect : [activeId];
  overId = overId.replace('_menu', '');

  // Added the below line to make the folder open action to be async if the dropped folder is opened immediately
  setDroppedDataFolderId({ overId });

  // Update Size
  updateItemSize({
    containerArray: captureLists,
    activeIds: captureIds,
    overId,
    overContainer: CONTAINER_TYPES.MY_FILES,
  });

  // Remove Item From Capture
  removeItemsFromCaptureHelper({
    activeId,
    isMultipleCaptureSelect,
  });

  // API Call
  moveCaptureItemToOthersHelper({
    activeIds: captureIds,
    targetId: overId,
    extractName,
    targetContainer: CONTAINER_TYPES.MY_FILES,
    isOverIndexSection,
  });
}
function moveCaptureItemToMyFilesNewFolder({ overContainerId, isCategory }) {
  dndCreateModalVisibility({
    module: 'folderCreateModalVisibility',
    overContainerId,
    isCategory,
    dropAction: DND_CAPTURE_ITEM_DROP_ON_MY_FILES_CREATION_CONTAINER,
  });
}
function moveJotsItemToMyFiles({
  activeId,
  overContainerId,
  extractName,
  activeContainerId,
  isMultipleJotsItemSelect,
}) {
  const { jotsProjectItems, jotsMultiSelect } = store.getState().jotsReducer;
  const { boardDetails } = store.getState().BoardGroups;
  const multiSelectArray = jotsMultiSelect.map((item) => item.id);
  const itemDetails = isMultipleJotsItemSelect
    ? jotsProjectItems?.filter((data) => multiSelectArray.includes(data?.id))
    : jotsProjectItems.find((data) => data.id === activeId);
  const activeIds = isMultipleJotsItemSelect
    ? jotsMultiSelect.map((item) => item.id)
    : [activeId];

  // Remove Item From Jots
  removeItemsFromJotsHelper({
    activeIds,
    isMultipleJotsItemSelect,
    restrictJotsMultiSelectUpdate: true,
  });

  // Add Item To MyFiles
  addItemToMyFilesHelper({
    isMultipleSelectScenario: isMultipleJotsItemSelect,
    itemDetails,
    overContainerId,
    jotsProjectItems,
    myFilesItems: boardDetails,
  });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overContainerId,
    extractName,
    targetContainer: CONTAINER_TYPES.MY_FILES,
  });
}
function moveJotsItemToMyFilesPage({
  activeId,
  overId,
  activeContainerId,
  extractName,
  isMultipleJotsItemSelect,
}) {
  const { jotsMultiSelect, jotsProjectItems } = store.getState().jotsReducer;
  const multiSelectArray = jotsMultiSelect
    .filter((item) => item.objectType === 'item')
    .map((data) => data.id);
  const activeIds = isMultipleJotsItemSelect ? multiSelectArray : [activeId];

  // Add Item to Jots Page
  addItemToPage({
    activeId,
    multiSelectArray,
    pageId: overId,
    isMultipleSelectScenario: isMultipleJotsItemSelect,
    from: CONTAINER_TYPES.JOTS,
  });

  // Update Size
  updateItemSize({
    containerArray: jotsProjectItems,
    activeIds,
    overId,
    overContainer: CONTAINER_TYPES.MY_FILES,
  });

  // Remove Item From Jots
  removeItemsFromJotsHelper({ activeIds, isMultipleJotsItemSelect });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overId,
    extractName,
    overDocument: true,
    targetContainer: CONTAINER_TYPES.MY_FILES,
  });
}
function moveJotsItemToMyFilesNewPage({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'folderCreateModalVisibility',
    selectedType: 'document',
    overContainerId,
    dropAction: DND_JOTS_ITEM_DROP_ON_MY_FILES_CREATION_CREATION_CONTAINER,
  });
}
function moveJotsItemToFolder({
  activeId,
  overId,
  activeContainerId,
  extractName,
  isOverIndexSection = false,
  isMultipleJotsItemSelect,
}) {
  const { jotsMultiSelect, jotsProjectItems } = store.getState().jotsReducer;
  const activeIds = isMultipleJotsItemSelect
    ? jotsMultiSelect.map((item) => item.id)
    : [activeId];

  // Added the below line to make the folder open action to be async if the dropped folder is opened immediately
  setDroppedDataFolderId({ overId });

  // Update Size
  updateItemSize({
    containerArray: jotsProjectItems,
    activeIds,
    overId,
    overContainer: CONTAINER_TYPES.MY_FILES,
  });

  // Remove Item From Jots
  removeItemsFromJotsHelper({
    activeIds,
    isMultipleJotsItemSelect,
    restrictJotsMultiSelectUpdate: true,
  });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overId.replace(replaceString, ''),
    extractName,
    overFolder: true,
    targetContainer: CONTAINER_TYPES.MY_FILES,
    isOverIndexSection,
  });
}
function moveJotsItemToMyFilesNewFolder({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'folderCreateModalVisibility',
    overContainerId,
    dropAction: DND_JOTS_ITEM_DROP_ON_MY_FILES_CREATION_CREATION_CONTAINER,
  });
}
function moveJotsPageToMyFiles({
  activeId,
  overContainerId,
  activeContainerId,
  extractName,
  isMultipleJotsItemSelect,
  overId,
}) {
  const { jotsProjectItems, jotsMultiSelect } = store.getState().jotsReducer;
  const { boardDetails } = store.getState().BoardGroups;
  const multiSelectArray = jotsMultiSelect.map((item) => item.id);
  const itemDetails = isMultipleJotsItemSelect
    ? jotsProjectItems?.filter((data) => multiSelectArray.includes(data?.id))
    : jotsProjectItems.find((data) => data.id === activeId);
  const activeIds = isMultipleJotsItemSelect
    ? jotsMultiSelect.map((item) => item.id)
    : [activeId];
  // Remove Item From Jots
  removeItemsFromJotsHelper({
    activeIds,
    isMultipleJotsItemSelect,
    restrictJotsMultiSelectUpdate: true,
  });

  // Add Item To MyFiles
  addItemToMyFilesHelper({
    isMultipleSelectScenario: isMultipleJotsItemSelect,
    itemDetails,
    overContainerId,
    jotsProjectItems,
    myFilesItems: boardDetails,
  });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overContainerId,
    extractName,
    targetContainer: CONTAINER_TYPES.MY_FILES,
  });
}
function moveJotsPageToFolder({
  activeId,
  overId,
  activeContainerId,
  extractName,
  isOverIndexSection = false,
  isMultipleJotsItemSelect,
}) {
  const { jotsMultiSelect, jotsProjectItems } = store.getState().jotsReducer;
  const activeIds = isMultipleJotsItemSelect
    ? jotsMultiSelect.map((item) => item.id)
    : [activeId];

  // Added the below line to make the folder open action to be async if the dropped folder is opened immediately
  setDroppedDataFolderId({ overId });

  // Update Size
  updateItemSize({
    containerArray: jotsProjectItems,
    activeIds,
    overId,
    overContainer: CONTAINER_TYPES.MY_FILES,
  });

  // Remove Item From Jots
  removeItemsFromJotsHelper({
    activeIds,
    isMultipleJotsItemSelect,
    restrictJotsMultiSelectUpdate: true,
  });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overId.replace(replaceString, ''),
    overFolder: true,
    extractName,
    targetContainer: CONTAINER_TYPES.MY_FILES,
    isOverIndexSection,
  });
}
function moveJotsPageToNewFolder({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'folderCreateModalVisibility',
    overContainerId,
    dropAction: DND_JOTS_PAGE_DROP_ON_MY_FILES_CREATION_CONTAINER,
  });
}
function moveMyFilesItemToJots({
  activeId,
  activeContainerId,
  overContainerId,
  overContainer,
  extractName,
  isMultipleMyFilesItemSelect,
}) {
  const { boardDetails, myFilesMultiSelect } = store.getState().BoardGroups;
  const { jotsProjectItems } = store.getState().jotsReducer;
  const multiSelectArray = myFilesMultiSelect.map((item) => item.id);
  const itemDetails = isMultipleMyFilesItemSelect
    ? boardDetails?.filter((data) => multiSelectArray.includes(data?.id))
    : boardDetails.find((data) => data.id === activeId);
  let activeIds = [];
  if (isMultipleMyFilesItemSelect) {
    activeIds = myFilesMultiSelect
      .filter((item) => ['item', 'page'].includes(item.objectType))
      .map((item) => item.id);
  } else {
    activeIds = [activeId];
  }

  // Remove Item From MyFiles
  removeItemFromMyFilesHelper({
    activeIds,
    isMultipleMyFilesItemSelect,
    overContainer,
  });

  // Add Item To Jots
  addItemToJotsHelper({
    isMultipleSelectScenario: isMultipleMyFilesItemSelect,
    itemDetails,
    overContainerId,
    jotsProjectItems,
  });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overContainerId,
    extractName,
    targetContainer: CONTAINER_TYPES.JOTS,
  });
}
function moveMyFilesPageToJots({
  activeId,
  overContainerId,
  overId,
  overContainer,
  extractName,
  isMultipleMyFilesItemSelect,
  activeContainerId,
}) {
  const { boardDetails, myFilesMultiSelect } = store.getState().BoardGroups;
  const { jotsProjectItems } = store.getState().jotsReducer;
  const multiSelectArray = myFilesMultiSelect.map((item) => item.id);
  const itemDetails = isMultipleMyFilesItemSelect
    ? boardDetails?.filter((data) => multiSelectArray.includes(data?.id))
    : boardDetails.find((data) => data.id === activeId);
  let activeIds = [];
  if (isMultipleMyFilesItemSelect) {
    activeIds = myFilesMultiSelect
      .filter((item) => ['item', 'page'].includes(item.objectType))
      .map((item) => item.id);
  } else {
    activeIds = [activeId];
  }

  // Remove Item From MyFiles
  removeItemFromMyFilesHelper({
    activeIds,
    isMultipleMyFilesItemSelect,
    overContainer,
  });

  // Add Item To Jots
  addItemToJotsHelper({
    isMultipleSelectScenario: isMultipleMyFilesItemSelect,
    itemDetails,
    overContainerId,
    jotsProjectItems,
  });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overContainerId,
    extractName,
    targetContainer: CONTAINER_TYPES.JOTS,
  });
}
function moveMyFilesPageToFolder({
  activeId,
  overId,
  extractName,
  isOverIndexSection = false,
  isMultipleMyFilesItemSelect,
  overContainer,
  activeContainerId,
}) {
  const { myFilesMultiSelect, boardDetails } = store.getState().BoardGroups;
  let activeIds = [];
  if (isMultipleMyFilesItemSelect) {
    activeIds = myFilesMultiSelect.map((item) => item.id);
  } else {
    activeIds = [activeId];
  }

  // Added the below line to make the folder open action to be async if the dropped folder is opened immediately
  setDroppedDataFolderId({ overId });

  // Update Size
  updateItemSize({
    containerArray: boardDetails,
    activeIds,
    overId,
    overContainer: CONTAINER_TYPES.MY_FILES,
  });
  removeItemFromMyFilesHelper({
    activeIds,
    isMultipleMyFilesItemSelect,
    overContainer,
    toFolder: true,
  });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overId.replace(replaceString, ''),
    extractName,
    overFolder: true,
    targetContainer: CONTAINER_TYPES.MY_FILES,
    isOverIndexSection,
  });
}
function moveMyFilesPageToNewFolder({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'folderCreateModalVisibility',
    overContainerId,
    dropAction: DND_MY_FILES_PAGE_DROP_ON_MY_FILES_CREATION_CONTAINER,
  });
}
function moveMyFilesFolderToFolder({
  activeId,
  overId,
  extractName,
  isOverIndexSection = false,
  isMultipleMyFilesItemSelect,
  overContainer,
  activeContainerId,
  sourceType,
  hasRootUpdate = false,
}) {
  const { myFilesMultiSelect, boardDetails } = store.getState().BoardGroups;
  let activeFolderIds = [];
  if (isMultipleMyFilesItemSelect) {
    activeFolderIds = myFilesMultiSelect
      .filter((item) => item.objectType === 'folder')
      .map((item) => item.id);
  } else {
    activeFolderIds = [activeId];
  }
  if (!activeFolderIds.includes(overId)) {
    let activeIds = [];
    if (isMultipleMyFilesItemSelect) {
      activeIds = myFilesMultiSelect.map((item) => item.id);
    } else {
      activeIds = [activeId];
    }

    // Added the below line to make the folder open action to be async if the dropped folder is opened immediately
    if(overId) {
      setDroppedDataFolderId({ overId });
    }
    // Update Size
    updateItemSize({
      containerArray: boardDetails,
      activeIds,
      overId,
      overContainer: CONTAINER_TYPES.MY_FILES,
    });
    // To remove the Item from Index Section
    activeFolderIds.forEach((id) => updateIndexSection({ deletedId: id }));

    // Remove Items from MyFiles Reducer
    removeItemFromMyFilesHelper({
      activeIds,
      isMultipleMyFilesItemSelect,
      overContainer,
      toFolder: ![CONTAINER_TYPES.MY_FILES_CATEGORY, CONTAINER_TYPES.INDEX_SECTION_CATEGORY].includes(overContainer),
      toCategory: [CONTAINER_TYPES.MY_FILES_CATEGORY, CONTAINER_TYPES.INDEX_SECTION_CATEGORY].includes(overContainer),
      sourceType,
    });

    // API Call
    moveAPI({
      activeIds,
      parentId: activeContainerId,
      targetId: overId && overId.replace(replaceString, ''),
      extractName,
      overFolder: true,
      targetContainer: CONTAINER_TYPES.MY_FILES,
      isOverIndexSection,
      hasRootUpdate,
    });
  }
}
function convertMyFilesFolderToCategory({
  activeId,
  overId,
  extractName,
  isOverIndexSection = false,
  isMultipleMyFilesItemSelect,
  overContainer,
  activeContainerId,
}) {
  if (activeContainerId !== overId.replace(replaceString, '')) {
    const { myFilesMultiSelect } = store.getState().BoardGroups;
    let activeIds = [];
    if (isMultipleMyFilesItemSelect) {
      activeIds = myFilesMultiSelect
        .filter((item) => item.objectType === 'folder')
        .map((item) => item.id);
    } else {
      activeIds = [activeId];
    }

    // To remove the Item from Index Section
    activeIds.forEach((id) => updateIndexSection({ deletedId: id }));

    // Remove Items from MyFiles Reducer
    removeItemFromMyFilesHelper({
      activeIds,
      isMultipleMyFilesItemSelect,
      overContainer,
      toCategory: true,
    });
    // API Call
    moveAPI({
      activeIds,
      parentId: activeContainerId,
      targetId: null,
      extractName,
      overCategory: true,
      targetContainer: CONTAINER_TYPES.MY_FILES,
      isOverIndexSection,
      hasRootUpdate: true,
    });
  }
}
function moveMyFilesFolderToNewFolder({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'folderCreateModalVisibility',
    overContainerId,
    dropAction: DND_MY_FILES_FOLDER_DROP_ON_MY_FILES_CREATION_CONTAINER,
  });
}
function moveMyFilesItemToCapture({
  activeId,
  activeContainerId,
  extractName,
  isMultipleMyFilesItemSelect,
}) {
  const { boardDetails, myFilesMultiSelect } = store.getState().BoardGroups;
  const { captureLists } = store.getState().Capture;
  let activeIds = [];
  if (isMultipleMyFilesItemSelect) {
    activeIds = myFilesMultiSelect
      .filter((item) => item.objectType === 'item')
      .map((item) => item.id);
  } else {
    activeIds = [activeId];
  }
  const itemDetails = isMultipleMyFilesItemSelect
    ? boardDetails?.filter((data) => activeIds.includes(data?.id))
    : boardDetails.find((data) => data.id === activeId);

  // Remove Item from MyFiles
  removeItemFromMyFilesHelper({
    activeIds,
    isMultipleMyFilesItemSelect,
    toCapture: true,
  });

  // Add Item to Captures
  addItemToCaptureHelper({
    isMultipleSelectScenario: isMultipleMyFilesItemSelect,
    itemDetails,
    captureLists,
  });

  // API Call
  moveItemsToCapture({
    activeIds,
    parentId: activeContainerId,
    extractName,
  });
}
function moveMyFilesItemToJotsPage({
  activeId,
  overId,
  overContainer,
  activeContainerId,
  extractName,
  isMultipleMyFilesItemSelect,
}) {
  const { myFilesMultiSelect, boardDetails } = store.getState().BoardGroups;
  let activeIds = [];
  if (isMultipleMyFilesItemSelect) {
    activeIds = myFilesMultiSelect
      .filter((item) => item.objectType === 'item')
      .map((item) => item.id);
  } else {
    activeIds = [activeId];
  }

  // Add Item to Jots Page
  addItemToPage({
    activeId,
    multiSelectArray: activeIds,
    pageId: overId,
    toJotsPage: true,
    isMultipleSelectScenario: isMultipleMyFilesItemSelect,
    from: CONTAINER_TYPES.JOTS,
  });

  // Update Size
  updateItemSize({
    containerArray: boardDetails,
    activeIds,
    overId,
    overContainer: CONTAINER_TYPES.JOTS,
  });

  // Remove Item From MyFiles
  removeItemFromMyFilesHelper({
    activeIds,
    isMultipleMyFilesItemSelect,
    overContainer,
    toPage: true,
  });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overId.replace(replaceString, ''),
    extractName,
    overDocument: true,
    targetContainer: CONTAINER_TYPES.JOTS,
  });
}
function moveMyFilesItemToJotsNewPage({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'homeNewFolderVisibility',
    selectedType: 'document',
    overContainerId,
    dropAction: DND_MY_FILES_ITEM_DROP_ON_JOTS_CREATION_CONTAINER,
  });
}
function moveMyFilesItemToMyFilesPage({
  activeId,
  overId,
  activeContainerId,
  extractName,
  isMultipleMyFilesItemSelect,
  overContainer,
}) {
  const { myFilesMultiSelect, boardDetails } = store.getState().BoardGroups;
  let activeIds = [];
  if (isMultipleMyFilesItemSelect) {
    activeIds = myFilesMultiSelect
      .filter((item) => item.objectType === 'item')
      .map((item) => item.id);
  } else {
    activeIds = [activeId];
  }

  // Add Item to Jots Page
  addItemToPage({
    activeId,
    multiSelectArray: activeIds,
    pageId: overId,
    isMultipleSelectScenario: isMultipleMyFilesItemSelect,
    from: CONTAINER_TYPES.MY_FILES,
  });

  // Update Size
  updateItemSize({
    containerArray: boardDetails,
    activeIds,
    overId,
    overContainer: CONTAINER_TYPES.MY_FILES,
  });

  // Remove Item From MyFiles
  removeItemFromMyFilesHelper({
    activeIds,
    isMultipleMyFilesItemSelect,
    overContainer,
    toPage: true,
  });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overId.replace(replaceString, ''),
    extractName,
    overDocument: true,
    targetContainer: CONTAINER_TYPES.MY_FILES,
  });
}
function moveMyFilesItemItemToMyFilesNewPage({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'folderCreateModalVisibility',
    selectedType: 'document',
    overContainerId,
    dropAction: DND_MY_FILES_ITEM_DROP_ON_MY_FILES_CREATION_CONTAINER,
  });
}
function moveMyFilesItemToFolder({
  activeId,
  overId,
  activeContainerId,
  extractName,
  isOverIndexSection = false,
  isMultipleMyFilesItemSelect,
  overContainer,
}) {
  const { myFilesMultiSelect, boardDetails } = store.getState().BoardGroups;
  let activeIds = [];
  if (isMultipleMyFilesItemSelect) {
    activeIds = myFilesMultiSelect
      .filter((item) => ['page', 'item'].includes(item.objectType))
      .map((item) => item.id);
  } else {
    activeIds = [activeId];
  }

  // Added the below line to make the folder open action to be async if the dropped folder is opened immediately
  setDroppedDataFolderId({ overId });

  // Update Size
  updateItemSize({
    containerArray: boardDetails,
    activeIds,
    overId,
    overContainer: CONTAINER_TYPES.MY_FILES,
  });

  removeItemFromMyFilesHelper({
    activeIds,
    isMultipleMyFilesItemSelect,
    overContainer,
    toFolder: true,
  });

  // API Call
  moveAPI({
    activeIds,
    parentId: activeContainerId,
    targetId: overId.replace(replaceString, ''),
    extractName,
    overFolder: true,
    targetContainer: CONTAINER_TYPES.MY_FILES,
    isOverIndexSection,
  });
}
function moveMyFilesItemToNewFolder({ overContainerId }) {
  dndCreateModalVisibility({
    module: 'folderCreateModalVisibility',
    overContainerId,
    dropAction: DND_MY_FILES_ITEM_DROP_ON_MY_FILES_CREATION_CONTAINER,
  });
}
function reorderJots({
  activeId,
  activeItemType,
  activeSortableIndex,
  overSortableIndex,
}) {
  const oldIndex = activeSortableIndex,
    newIndex = overSortableIndex,
    { jotsProjectItems } = store.getState().jotsReducer;

  if (oldIndex !== newIndex) {
    // Reorder the Jots
    reorderHelper({
      oldIndex,
      newIndex,
      projectItems: jotsProjectItems,
      container: CONTAINER_TYPES.JOTS,
    });

    // API Call
    reorderItems({
      activeItemType,
      oldIndex,
      newIndex,
      objectId: activeId,
    });
  }
}
function reorderFullScreenDocumentHelper({
  activeId,
  initiatorContainer,
  activeContainerId,
  activeSortableIndex,
  overSortableIndex,
}) {
  const oldIndex = activeSortableIndex,
    newIndex = overSortableIndex;

  // Update in Reducer
  if (initiatorContainer === CONTAINER_TYPES.JOTS) {
    const { jotsProjectItems } = store.getState().jotsReducer;
    reorderDocumentItemHelper({
      projectItems: jotsProjectItems,
      activeContainerId,
      oldIndex,
      newIndex,
      initiatorContainer,
    });
  } else {
    const { boardDetails } = store.getState().BoardGroups;
    reorderDocumentItemHelper({
      projectItems: boardDetails,
      activeContainerId,
      oldIndex,
      newIndex,
      initiatorContainer,
    });
  }

  // API Call
  reorderItems({
    activeItemType: 'item',
    oldIndex,
    newIndex,
    objectId: activeId,
  });
}
function reorderMyFilesInsideBoard({
  activeId,
  activeItemType,
  activeContainerId,
  activeSortableIndex,
  overSortableIndex,
}) {
  const oldIndex = activeSortableIndex,
    newIndex = overSortableIndex,
    { boardDetails } = store.getState().BoardGroups;

  if (oldIndex !== newIndex) {
    // Reorder the Jots
    reorderHelper({
      oldIndex,
      newIndex,
      projectItems: boardDetails,
      container: CONTAINER_TYPES.MY_FILES,
    });

    // API Call
    reorderItems({
      activeItemType,
      oldIndex,
      newIndex,
      objectId: activeId,
    });
  }
}

function jotsFreeSpaceViewHelper({
  activeId,
  delta,
  activeFreeSpaceItemType,
  activeObjectType,
  activeItemIsURL,
}) {
  // Prevent URL redirection if URL item is dragged
  if (activeItemIsURL) {
    store.dispatch({
      type: commonActions.URL_PREVENT_DEFAULT,
    });
  }
  // Update the coordinates in reducer
  updateJotsFreeSpaceCoordinates({ activeId, delta });

  // API call
  freeSpaceViewAPIHelper({
    activeFreeSpaceItemType,
    activeId,
    activeObjectType,
    activeItemIsURL,
    from: CONTAINER_TYPES.JOTS,
  });
}

function myFilesFreeSpaceViewHelper({
  activeId,
  delta,
  activeFreeSpaceItemType,
  activeObjectType,
  activeItemIsURL,
}) {
  // Prevent URL redirection if URL item is dragged
  if (activeItemIsURL) {
    store.dispatch({
      type: commonActions.URL_PREVENT_DEFAULT,
    });
  }
  // Update the coordinates in reducer
  updateMyFilesFreeSpaceCoordinates({ activeId, delta });
  // API call
  freeSpaceViewAPIHelper({
    activeFreeSpaceItemType,
    activeId,
    activeObjectType,
    activeItemIsURL,
    from: CONTAINER_TYPES.MY_FILES,
  });
}
export const useDnDSensors = () =>
  useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

export const onDragCancel = (e) => {
  console.log('Drag Cancelled');
};

export function onDragEnd({ active, over, delta }) {
  console.log('Drag End:', { active, over, delta });

  store.dispatch({
    type: commonActions.DRAG_START,
  });
  if (over !== null) {
    const {
      id: activeId = null,
      data: {
        current: {
          containerId: activeContainerId = null,
          container: activeContainer = null,
          type: activeItemType = null,
          objectType: activeObjectType = null,
          sourceName: activeItemName,
          projectId: activeProjectId = null,
          freeSpaceItemType: activeFreeSpaceItemType = null,
          isUrl: activeItemIsURL = null,
          isMultipleCaptureSelect = false,
          isMultipleJotsItemSelect = false,
          isMultipleMyFilesItemSelect = false,
          initiatorContainer = null,
          sortable: {
            containerId: activeSortableContainerId = null,
            index: activeSortableIndex = null,
          } = {},
        } = {},
      } = {},
    } = active || {};
    const {
      jotsIsReorderState = false,
      jotsView = 'grid',
    } = store.getState().jotsReducer;
    const {
      currentId = null,
      myFilesView = 'grid',
      isReorderState: myFilesIsReorderState = false,
    } = store.getState().BoardGroups;
    const {
      jots: jotsSortFilter = 'newest_first',
      [currentId]: myFilesSortFilter = 'newest_first',
    } = store.getState().commonReducer?.sortByFilter || {};
    const {
      id: overId = null,
      data: {
        current: {
          containerId: overContainerId = null,
          container: overContainer = null,
          type: overItemType = null,
          nodeId: overNodeId = null,
          destinationName: overItemName,
          isCategory: overCategory = false,
          sortable: {
            containerId: overSortableContainerId = null,
            index: overSortableIndex = null,
          } = {},
        } = {},
      } = {},
    } = over || {};

    const extractName = {
      sourceName: activeItemName,
      destinationName: overItemName,
    };
    const sourceType = active.data.current.type;
    const targetType = over.data.current.type;

    const reorderCondition =
      activeSortableContainerId !== null &&
      overSortableContainerId !== null &&
      activeSortableContainerId === overSortableContainerId;
    const jotsReorder =
      reorderCondition && jotsSortFilter === 'my_order' && jotsIsReorderState;
    const myFilesInsideBoardReorder =
      reorderCondition &&
      myFilesSortFilter === 'my_order' &&
      myFilesIsReorderState;
    const jotsFreeSpaceView =
      activeContainer === CONTAINER_TYPES.JOTS &&
      overContainer === CONTAINER_TYPES.JOTS &&
      jotsView === 'free_space';
    const myFilesFreeSpaceView =
      ((activeContainer === CONTAINER_TYPES.MY_FILES &&
        overContainer === CONTAINER_TYPES.MY_FILES) ||
        (activeContainer === CONTAINER_TYPES.MY_FILES_CATEGORY &&
          overContainer === CONTAINER_TYPES.MY_FILES_CATEGORY)) &&
      myFilesView === 'free_space';

    const setDragDetailsForCreationFlow = () => {
      store.dispatch({
        type: commonActions.SET_DRAG_DETAILS,
        payload: {
          active: {
            activeId,
            activeItemName,
            activeItemType,
            activeContainer,
            activeContainerId,
            activeProjectId,
            isMultipleCaptureSelect,
            isMultipleJotsItemSelect,
            isMultipleMyFilesItemSelect,
            extractName,
          },
          over: {
            overId,
            overContainer,
            overContainerId,
            overItemType,
            overItemName,
          },
        },
      });
    };
    if (jotsFreeSpaceView) {
      jotsFreeSpaceViewHelper({
        activeId,
        delta,
        activeFreeSpaceItemType,
        activeObjectType,
        activeItemIsURL,
      });
    } else if (myFilesFreeSpaceView) {
      myFilesFreeSpaceViewHelper({
        activeId,
        delta,
        activeFreeSpaceItemType,
        activeObjectType,
        activeItemIsURL,
      });
    } else if (jotsReorder) {
      reorderJots({
        activeId,
        activeItemType,
        activeContainerId,
        activeSortableIndex,
        overSortableIndex,
      });
    } else if (myFilesInsideBoardReorder) {
      reorderMyFilesInsideBoard({
        activeId,
        activeItemType,
        activeContainerId,
        activeSortableIndex,
        overSortableIndex,
      });
    } else {
      switch (sourceType) {
        case CONTAINER_TYPES.CAPTURE_ITEM:
          switch (targetType) {
            case CONTAINER_TYPES.INDEX_SECTION_CATEGORY:
            case CONTAINER_TYPES.MY_FILES_CATEGORY:
              moveCaptureItemToFolder({
                activeId,
                overId,
                overContainer,
                extractName,
                isMultipleCaptureSelect,
                targetContainer: targetType,
              });
              break;
            case CONTAINER_TYPES.JOTS:
            case CONTAINER_TYPES.JOTS_ITEM:
              moveCaptureItemToJots({
                activeId,
                overContainerId,
                extractName,
                isMultipleCaptureSelect,
                overContainer,
                overId,
              });
              break;
            case CONTAINER_TYPES.JOTS_PAGE:
            case CONTAINER_TYPES.JOTS_FREE_SPACE_PAGE:
              moveCaptureItemToJotsPage({
                activeId,
                overId,
                overContainerId,
                overContainer,
                extractName,
                isMultipleCaptureSelect,
              });
              break;
            case CONTAINER_TYPES.JOTS_CREATE_PAGE:
              setDragDetailsForCreationFlow();
              moveCaptureItemToJotsNewPage({
                overContainerId,
              });
              break;
            case CONTAINER_TYPES.MY_FILES:
            case CONTAINER_TYPES.MY_FILES_ITEM:
              moveCaptureItemToMyFiles({
                activeId,
                overContainerId,
                extractName,
                overId,
                overContainer,
                isMultipleCaptureSelect,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_PAGE:
            case CONTAINER_TYPES.MY_FILES_FREE_SPACE_PAGE:
              moveCaptureItemToMyFilesPage({
                activeId,
                overId,
                overContainerId,
                overContainer,
                extractName,
                isMultipleCaptureSelect,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_PAGE:
              setDragDetailsForCreationFlow();
              moveCaptureItemToMyFilesNewPage({
                overContainerId,
              });
              break;
            case CONTAINER_TYPES.FOLDER:
            case CONTAINER_TYPES.MY_FILES_FREE_SPACE_FOLDER:
              moveCaptureItemToFolder({
                activeId,
                overId,
                overContainer,
                extractName,
                isMultipleCaptureSelect,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_FOLDER:
              setDragDetailsForCreationFlow();
              moveCaptureItemToMyFilesNewFolder({
                overContainerId,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_CATEGORY:
              setDragDetailsForCreationFlow();
              moveCaptureItemToMyFilesNewFolder({
                overContainerId,
                isCategory: true,
              });
              break;
            case CONTAINER_TYPES.INDEX_SECTION_FOLDER:
              moveCaptureItemToFolder({
                activeId,
                overId: overNodeId,
                overContainer,
                extractName,
                isOverIndexSection: true,
                isMultipleCaptureSelect,
              });
              break;
            // Add other cases for CAPTURE source type here
            default:
              alertViewHelper({ sourceType, targetType, overCategory });
              break;
          }
          break;
        case CONTAINER_TYPES.JOTS_ITEM:
          switch (targetType) {
            case CONTAINER_TYPES.INDEX_SECTION_CATEGORY:
            case CONTAINER_TYPES.MY_FILES_CATEGORY:
              moveJotsItemToFolder({
                activeId,
                overId,
                activeContainerId,
                extractName,
                isMultipleJotsItemSelect,
              });
              break;
            case CONTAINER_TYPES.CAPTURE:
              moveJotsItemToCapture({
                activeId,
                activeContainerId,
                extractName,
                isMultipleJotsItemSelect,
              });
              break;
            case CONTAINER_TYPES.JOTS_PAGE:
              moveJotsItemToJotsPage({
                activeId,
                overId,
                activeContainerId,
                extractName,
                isMultipleJotsItemSelect,
              });
              break;
            case CONTAINER_TYPES.JOTS_CREATE_PAGE:
              setDragDetailsForCreationFlow();
              moveJotsItemToJotsNewPage({
                overContainerId,
              });
              break;
            case CONTAINER_TYPES.MY_FILES:
            case CONTAINER_TYPES.MY_FILES_ITEM:
              moveJotsItemToMyFiles({
                activeId,
                overId,
                extractName,
                overContainer,
                overContainerId,
                activeContainerId,
                isMultipleJotsItemSelect,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_PAGE:
            case CONTAINER_TYPES.MY_FILES_FREE_SPACE_PAGE:
              moveJotsItemToMyFilesPage({
                activeId,
                overId,
                activeContainerId,
                extractName,
                isMultipleJotsItemSelect,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_PAGE:
              setDragDetailsForCreationFlow();
              moveJotsItemToMyFilesNewPage({ overContainerId });
              break;
            case CONTAINER_TYPES.FOLDER:
            case CONTAINER_TYPES.MY_FILES_FREE_SPACE_FOLDER:
              moveJotsItemToFolder({
                activeId,
                overId,
                activeContainerId,
                extractName,
                isMultipleJotsItemSelect,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_FOLDER:
              setDragDetailsForCreationFlow();
              moveJotsItemToMyFilesNewFolder({ overContainerId });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_CATEGORY:
              setDragDetailsForCreationFlow();
              moveCaptureItemToMyFilesNewFolder({
                overContainerId,
                isCategory: true,
              });
              break;
            case CONTAINER_TYPES.INDEX_SECTION_FOLDER:
              moveJotsItemToFolder({
                activeId,
                overId: overNodeId,
                activeContainerId,
                extractName,
                isOverIndexSection: true,
                isMultipleJotsItemSelect,
              });
              break;
            // Add other cases for CAPTURE source type here
            default:
              alertViewHelper({ sourceType, targetType, overCategory });
              break;
          }
          break;
        case CONTAINER_TYPES.JOTS_PAGE:
        case CONTAINER_TYPES.JOTS_FREE_SPACE_PAGE:
          switch (targetType) {
            case CONTAINER_TYPES.MY_FILES:
            case CONTAINER_TYPES.MY_FILES_ITEM:
            case CONTAINER_TYPES.MY_FILES_PAGE:
            case CONTAINER_TYPES.MY_FILES_FREE_SPACE_PAGE:
              moveJotsPageToMyFiles({
                activeId,
                overContainerId,
                activeContainerId,
                extractName,
                isMultipleJotsItemSelect,
                overId,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CATEGORY:
            case CONTAINER_TYPES.FOLDER:
            case CONTAINER_TYPES.MY_FILES_FREE_SPACE_FOLDER:
              moveJotsPageToFolder({
                activeId,
                overId,
                activeContainerId,
                extractName,
                isMultipleJotsItemSelect,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_FOLDER:
              setDragDetailsForCreationFlow();
              moveJotsPageToNewFolder({ overContainerId });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_CATEGORY:
              setDragDetailsForCreationFlow();
              moveCaptureItemToMyFilesNewFolder({
                overContainerId,
                isCategory: true,
              });
              break;
            case CONTAINER_TYPES.INDEX_SECTION_CATEGORY:
            case CONTAINER_TYPES.INDEX_SECTION_FOLDER:
              moveJotsPageToFolder({
                activeId,
                overId,
                activeContainerId,
                extractName,
                isMultipleJotsItemSelect,
                isOverIndexSection: true,
              });
              break;
            // Add other cases for CAPTURE source type here
            default:
              alertViewHelper({ sourceType, targetType, overCategory });
              break;
          }
          break;
        case CONTAINER_TYPES.MY_FILES_PAGE:
        case CONTAINER_TYPES.MY_FILES_FREE_SPACE_PAGE:
          switch (targetType) {
            case CONTAINER_TYPES.JOTS:
            case CONTAINER_TYPES.JOTS_ITEM:
            case CONTAINER_TYPES.JOTS_PAGE:
            case CONTAINER_TYPES.JOTS_FREE_SPACE_PAGE:
              moveMyFilesPageToJots({
                activeId,
                overContainerId,
                overId,
                overContainer,
                extractName,
                isMultipleMyFilesItemSelect,
                activeContainerId,
              });
              break;
            case CONTAINER_TYPES.FOLDER:
              moveMyFilesPageToFolder({
                activeId,
                overId,
                extractName,
                isMultipleMyFilesItemSelect,
                overContainer,
                activeContainerId,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_FOLDER:
              setDragDetailsForCreationFlow();
              moveMyFilesPageToNewFolder({ overContainerId });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_CATEGORY:
              setDragDetailsForCreationFlow();
              moveCaptureItemToMyFilesNewFolder({
                overContainerId,
                isCategory: true,
              });
              break;
            case CONTAINER_TYPES.INDEX_SECTION_FOLDER:
              moveMyFilesPageToFolder({
                activeId,
                overId,
                extractName,
                isOverIndexSection: true,
                isMultipleMyFilesItemSelect,
                overContainer,
                activeContainerId,
              });
              break;
            default:
              alertViewHelper({ sourceType, targetType, overCategory });
              break;
          }
          break;
        case CONTAINER_TYPES.FOLDER:
        case CONTAINER_TYPES.MY_FILES_FREE_SPACE_FOLDER:
          switch (targetType) {
            case CONTAINER_TYPES.FOLDER:
              moveMyFilesFolderToFolder({
                activeId,
                overId,
                extractName,
                isMultipleMyFilesItemSelect,
                overContainer,
                activeContainerId,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_FOLDER:
              setDragDetailsForCreationFlow();
              moveMyFilesFolderToNewFolder({ overContainerId });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_CATEGORY:
              setDragDetailsForCreationFlow();
              moveCaptureItemToMyFilesNewFolder({
                overContainerId,
                isCategory: true,
              });
              break;
            case CONTAINER_TYPES.INDEX_SECTION_FOLDER:
              moveMyFilesFolderToFolder({
                activeId,
                overId,
                extractName,
                isOverIndexSection: true,
                isMultipleMyFilesItemSelect,
                overContainer,
                activeContainerId,
              });
              break;
            case CONTAINER_TYPES.INDEX_SECTION:
            case CONTAINER_TYPES.INDEX_SECTION_CATEGORY:
              convertMyFilesFolderToCategory({
                activeId,
                overId,
                extractName,
                isOverIndexSection: true,
                isMultipleMyFilesItemSelect,
                overContainer,
                activeContainerId,
              });
              break;
            default:
              alertViewHelper({ sourceType, targetType, overCategory });
              break;
          }
          break;
        case CONTAINER_TYPES.MY_FILES_ITEM:
          switch (targetType) {
            case CONTAINER_TYPES.JOTS:
              moveMyFilesItemToJots({
                activeId,
                activeContainerId,
                overContainerId,
                overId,
                overContainer,
                extractName,
                isMultipleMyFilesItemSelect,
              });
              break;
            case CONTAINER_TYPES.CAPTURE:
              moveMyFilesItemToCapture({
                activeId,
                activeContainerId,
                extractName,
                isMultipleMyFilesItemSelect,
              });
              break;
            case CONTAINER_TYPES.JOTS_PAGE:
            case CONTAINER_TYPES.JOTS_FREE_SPACE_PAGE:
              moveMyFilesItemToJotsPage({
                activeId,
                overId,
                activeProjectId,
                activeContainerId,
                extractName,
                overContainerId,
                isMultipleMyFilesItemSelect,
              });
              break;
            case CONTAINER_TYPES.JOTS_CREATE_PAGE:
              setDragDetailsForCreationFlow();
              moveMyFilesItemToJotsNewPage({ overContainerId });
              break;
            case CONTAINER_TYPES.MY_FILES_PAGE:
              moveMyFilesItemToMyFilesPage({
                activeId,
                overId,
                activeContainerId,
                extractName,
                isMultipleMyFilesItemSelect,
                overContainer,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_PAGE:
              setDragDetailsForCreationFlow();
              moveMyFilesItemItemToMyFilesNewPage({
                overContainerId,
              });
              break;
            case CONTAINER_TYPES.FOLDER:
              moveMyFilesItemToFolder({
                activeId,
                overId,
                activeContainerId,
                extractName,
                isMultipleMyFilesItemSelect,
                overContainer,
              });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_FOLDER:
              setDragDetailsForCreationFlow();
              moveMyFilesItemToNewFolder({ overContainerId });
              break;
            case CONTAINER_TYPES.MY_FILES_CREATE_CATEGORY:
              setDragDetailsForCreationFlow();
              moveCaptureItemToMyFilesNewFolder({
                overContainerId,
                isCategory: true,
              });
              break;
            case CONTAINER_TYPES.INDEX_SECTION_FOLDER:
              moveMyFilesItemToFolder({
                activeId,
                overId: overNodeId,
                activeContainerId,
                extractName,
                isOverIndexSection: true,
                isMultipleMyFilesItemSelect,
                overContainer,
              });
              break;
            // Add other cases for CAPTURE source type here
            default:
              alertViewHelper({ sourceType, targetType, overCategory });
              break;
          }
          break;
        case CONTAINER_TYPES.FULL_SCREEN_PAGE:
          switch (targetType) {
            case CONTAINER_TYPES.FULL_SCREEN_PAGE:
              reorderFullScreenDocumentHelper({
                activeId,
                activeProjectId,
                initiatorContainer,
                activeContainerId,
                activeSortableIndex,
                overSortableIndex,
              });
              break;
            default:
              alertViewHelper({ sourceType, targetType, overCategory });
              break;
          }
          break;
        case CONTAINER_TYPES.MY_FILES_CATEGORY:
          switch (targetType) {
            case CONTAINER_TYPES.INDEX_SECTION_CATEGORY:
            case CONTAINER_TYPES.MY_FILES_CATEGORY:
              moveMyFilesFolderToFolder({
                activeId,
                overId,
                extractName,
                isMultipleMyFilesItemSelect,
                overContainer,
                activeContainerId,
                isOverIndexSection: true,
                sourceType,
                hasRootUpdate: true,
              });
              break;
            case CONTAINER_TYPES.INDEX_SECTION_FOLDER:
              moveMyFilesFolderToFolder({
                activeId,
                overId: overNodeId,
                extractName,
                isOverIndexSection: true,
                isMultipleMyFilesItemSelect,
                overContainer,
                activeContainerId,
                sourceType,
              });
              break;
            default:
              alertViewHelper({ sourceType, targetType, overCategory });
              break;
          }
          break;
        // Add other cases for different source types here
        default:
          alertViewHelper({ sourceType, targetType, overCategory });
          break;
      }
    }
  } else {
    alertViewHelper({
      overNull: true,
      overCategory: over?.data?.current?.isCategory,
    });
  }
}

export function handleObjectMoveAfterCreation ({ response }) {
  const  {
    activeDragData,
    overDragData,
    dropAction,
  } = store.getState().commonReducer;
  const { captureMultiSelect } = store.getState().Capture;
  if (
      dropAction === DND_CAPTURE_ITEM_DROP_ON_JOTS_DOCUMENT_CREATION_CONTAINER
  ) {
    // Add Item to Jots Page
    addItemToPage({
      activeId: activeDragData?.activeId,
      multiSelectArray: captureMultiSelect,
      pageId: response?.data?.id,
      toJotsPage: true,
      isMultipleSelectScenario: activeDragData?.isMultipleCaptureSelect,
      from: CONTAINER_TYPES.CAPTURE,
    });
    const captureIds = activeDragData?.isMultipleCaptureSelect
        ? captureMultiSelect
        : [activeDragData?.activeId];
    const { captureLists } = store.getState().Capture;
    // Update Size
    updateItemSize({
      containerArray: captureLists,
      activeIds: captureIds,
      overId: response?.data?.id,
      overContainer: CONTAINER_TYPES.JOTS,
    });

    // Remove Item From Capture
    removeItemsFromCaptureHelper({
      activeId: activeDragData?.activeId,
      isMultipleCaptureSelect: activeDragData?.isMultipleCaptureSelect,
    });

    // API Call
    moveAPI({
      activeIds: captureIds,
      parentId: activeDragData?.activeContainerId,
      targetId: response?.data?.id,
      extractName: {
        ...activeDragData?.extractName,
        destinationName: response?.data?.name,
      },
      overDocument: true,
      targetContainer: CONTAINER_TYPES.JOTS,
    });
  } else if (
      dropAction === DND_JOTS_ITEM_DROP_ON_JOTS_DOCUMENT_CREATION_CONTAINER
  ) {
    const {
      jotsMultiSelect,
      jotsProjectItems,
    } = store.getState().jotsReducer;
    const multiSelectArray = jotsMultiSelect
        .filter((item) => item.objectType === 'item')
        .map((data) => data.id);
    const activeIds = activeDragData?.isMultipleJotsItemSelect
        ? multiSelectArray
        : [activeDragData?.activeId];

    // Add Item to Jots Page
    addItemToPage({
      activeId: activeDragData?.activeId,
      multiSelectArray,
      pageId: response?.data?.id,
      toJotsPage: true,
      isMultipleSelectScenario: activeDragData?.isMultipleCaptureSelect,
      from: CONTAINER_TYPES.JOTS,
    });

    // Update Size
    updateItemSize({
      containerArray: jotsProjectItems,
      activeIds,
      overId: response?.data?.id,
      overContainer: CONTAINER_TYPES.JOTS,
    });

    // Remove Item From Jots
    removeItemsFromJotsHelper({
      activeIds,
      isMultipleJotsItemSelect: activeDragData?.isMultipleJotsItemSelect,
    });

    // API Call
    moveAPI({
      activeIds,
      parentId: activeDragData?.activeContainerId,
      targetId: response?.data?.id,
      extractName: {
        ...activeDragData?.extractName,
        destinationName: response?.data?.name,
      },
      overDocument: true,
      targetContainer: CONTAINER_TYPES.JOTS,
    });
  } else if (
      dropAction === DND_CAPTURE_ITEM_DROP_ON_MY_FILES_CREATION_CONTAINER
  ) {
    if (overDragData?.overId !== 'my-files-dnd-create-folder') {
      // Add Item to MyFiles Page
      addItemToPage({
        activeId: activeDragData?.activeId,
        multiSelectArray: activeDragData?.isMultipleCaptureSelect
            ? captureMultiSelect
            : [activeDragData?.activeId],
        pageId: response?.data?.id,
        isMultipleSelectScenario: activeDragData?.isMultipleCaptureSelect,
        from: CONTAINER_TYPES.CAPTURE,
      });
    }
    const { captureLists } = store.getState().Capture;
    const captureIds = activeDragData?.isMultipleCaptureSelect
        ? captureMultiSelect
        : [activeDragData?.activeId];

    // Update Size
    updateItemSize({
      containerArray: captureLists,
      activeIds: captureIds,
      overId: response?.data?.id,
      overContainer: CONTAINER_TYPES.MY_FILES,
    });

    // Remove Item from Capture
    removeItemsFromCaptureHelper({
      activeId: activeDragData?.activeId,
      isMultipleCaptureSelect: activeDragData?.isMultipleCaptureSelect,
    });

    moveCaptureItemToOthersHelper({
      activeIds: captureIds,
      targetId: response?.data?.id,
      extractName: {
        ...activeDragData?.extractName,
        destinationName: response?.data?.name,
      },
      overDocument:
          overDragData?.overItemType === CONTAINER_TYPES.MY_FILES_CREATE_PAGE,
      targetContainer: CONTAINER_TYPES.MY_FILES,
    });
  } else if (
      dropAction === DND_MY_FILES_ITEM_DROP_ON_MY_FILES_CREATION_CONTAINER
  ) {
    const { myFilesMultiSelect, boardDetails } = store.getState().BoardGroups;
    const objectTypeArray =
        overDragData?.overId === 'my-files-dnd-create-folder'
            ? ['page', 'item']
            : ['item'];
    let activeIds = [];
    if (activeDragData?.isMultipleMyFilesItemSelect) {
      activeIds = myFilesMultiSelect
          .filter((item) => objectTypeArray.includes(item.objectType))
          .map((item) => item.id);
    } else {
      activeIds = [activeDragData?.activeId];
    }

    if (overDragData?.overId !== 'my-files-dnd-create-folder') {
      // Add Item to Jots Page
      addItemToPage({
        activeId: activeDragData?.activeId,
        multiSelectArray: activeIds,
        pageId: response?.data?.id,
        isMultipleSelectScenario: activeDragData?.isMultipleCaptureSelect,
        from: CONTAINER_TYPES.MY_FILES,
      });
    }

    // Update Size
    updateItemSize({
      containerArray: boardDetails,
      activeIds,
      overId: response?.data?.id,
      overContainer: CONTAINER_TYPES.MY_FILES,
    });

    // Remove Item From MyFiles
    removeItemFromMyFilesHelper({
      activeIds,
      isMultipleMyFilesItemSelect:
      activeDragData?.isMultipleMyFilesItemSelect,
      overContainer: overDragData?.overContainer,
      toPage: true,
    });
    // API Call
    moveAPI({
      activeIds,
      parentId: activeDragData?.activeContainerId,
      targetId: response?.data?.id,
      extractName: {
        ...activeDragData?.extractName,
        destinationName: response?.data?.name,
      },
      overDocument:
          overDragData?.overItemType === CONTAINER_TYPES.MY_FILES_CREATE_PAGE,
      overFolder:
          overDragData?.overItemType === CONTAINER_TYPES.MY_FILES_CREATE_FOLDER,
      targetContainer: CONTAINER_TYPES.MY_FILES,
    });
  } else if (
      [
        DND_MY_FILES_PAGE_DROP_ON_MY_FILES_CREATION_CONTAINER,
        DND_MY_FILES_FOLDER_DROP_ON_MY_FILES_CREATION_CONTAINER,
      ].includes(dropAction)
  ) {
    const { myFilesMultiSelect, boardDetails } = store.getState().BoardGroups;
    let activeIds = [];
    if (activeDragData?.isMultipleMyFilesItemSelect) {
      activeIds = myFilesMultiSelect.map((item) => item.id);
    } else {
      activeIds = [activeDragData?.activeId];
    }
    // Update Size
    updateItemSize({
      containerArray: boardDetails,
      activeIds,
      overId: response?.data?.id,
      overContainer: CONTAINER_TYPES.MY_FILES,
    });
    removeItemFromMyFilesHelper({
      activeIds,
      isMultipleMyFilesItemSelect:
      activeDragData?.isMultipleMyFilesItemSelect,
      overContainer: overDragData?.overContainer,
      toFolder: true,
    });

    let containsFolder;
    if (activeDragData?.isMultipleMyFilesItemSelect) {
      containsFolder = myFilesMultiSelect.some(
          (item) => item.objectType === 'folder',
      );
    } else {
      containsFolder = activeDragData?.activeItemType === 'folder';
    }
    // API Call
    moveAPI({
      activeIds,
      parentId: activeDragData?.activeContainerId,
      targetId: response?.data?.id,
      extractName: {
        ...activeDragData?.extractName,
        destinationName: response?.data?.name,
      },
      overDocument:
          overDragData?.overItemType === CONTAINER_TYPES.MY_FILES_CREATE_PAGE,
      overFolder:
          overDragData?.overItemType === CONTAINER_TYPES.MY_FILES_CREATE_FOLDER,
      targetContainer: CONTAINER_TYPES.MY_FILES,
      initiateGetAllGroupsList: containsFolder,
    });
  } else if (
      dropAction === DND_JOTS_ITEM_DROP_ON_MY_FILES_CREATION_CREATION_CONTAINER
  ) {
    const {
      jotsMultiSelect,
      jotsProjectItems,
    } = store.getState().jotsReducer;
    let activeIds = [];
    if (activeDragData?.isMultipleJotsItemSelect) {
      if (overDragData?.overId === 'my-files-dnd-create-folder') {
        activeIds = jotsMultiSelect.map((data) => data.id);
      } else {
        activeIds = jotsMultiSelect
            .filter((item) => item.objectType === 'item')
            .map((data) => data.id);
      }
    } else {
      activeIds = [activeDragData?.activeId];
    }

    if (overDragData?.overId !== 'my-files-dnd-create-folder') {
      // Add Item to Jots Page
      addItemToPage({
        activeId: activeDragData?.activeId,
        multiSelectArray: activeIds,
        pageId: response?.data?.id,
        isMultipleSelectScenario: activeDragData?.isMultipleCaptureSelect,
        from: CONTAINER_TYPES.JOTS,
      });
    }

    // Update Size
    updateItemSize({
      containerArray: jotsProjectItems,
      activeIds,
      overId: response?.data?.id,
      overContainer: CONTAINER_TYPES.MY_FILES,
    });

    // Remove Item From Jots
    removeItemsFromJotsHelper({
      activeIds,
      isMultipleJotsItemSelect: activeDragData?.isMultipleJotsItemSelect,
      restrictJotsMultiSelectUpdate:
          overDragData?.overId === 'my-files-dnd-create-folder',
    });

    // API Call
    moveAPI({
      activeIds,
      parentId: activeDragData?.activeContainerId,
      targetId: response?.data?.id,
      extractName: {
        ...activeDragData?.extractName,
        destinationName: response?.data?.name,
      },
      overDocument:
          overDragData?.overItemType === CONTAINER_TYPES.MY_FILES_CREATE_PAGE,
      overFolder:
          overDragData?.overItemType === CONTAINER_TYPES.MY_FILES_CREATE_FOLDER,
      targetContainer: CONTAINER_TYPES.MY_FILES,
      overCategory: overDragData?.overItemType === CONTAINER_TYPES.MY_FILES_CREATE_CATEGORY,
    });
  } else if (
      dropAction === DND_JOTS_PAGE_DROP_ON_MY_FILES_CREATION_CONTAINER
  ) {
    const {
      jotsMultiSelect,
      jotsProjectItems,
    } = store.getState().jotsReducer;
    const activeIds = activeDragData?.isMultipleJotsItemSelect
        ? jotsMultiSelect.map((item) => item.id)
        : [activeDragData?.activeId];
    // Update Size
    updateItemSize({
      containerArray: jotsProjectItems,
      activeIds,
      overId: response?.data?.id,
      overContainer: CONTAINER_TYPES.MY_FILES,
    });
    // Remove Item From Jots
    removeItemsFromJotsHelper({
      activeIds,
      isMultipleJotsItemSelect: activeDragData?.isMultipleJotsItemSelect,
      restrictJotsMultiSelectUpdate: true,
    });

    // API Call
    moveAPI({
      activeIds,
      parentId: activeDragData?.activeContainerId,
      targetId: response?.data?.id,
      extractName: {
        ...activeDragData?.extractName,
        destinationName: response?.data?.name,
      },
      overDocument:
          overDragData?.overItemType === CONTAINER_TYPES.MY_FILES_CREATE_PAGE,
      overFolder:
          overDragData?.overItemType === CONTAINER_TYPES.MY_FILES_CREATE_FOLDER,
      targetContainer: CONTAINER_TYPES.MY_FILES,
    });
  } else if (
      dropAction === DND_MY_FILES_ITEM_DROP_ON_JOTS_CREATION_CONTAINER
  ) {
    const { myFilesMultiSelect, boardDetails } = store.getState().BoardGroups;
    let activeIds = [];
    if (activeDragData?.isMultipleMyFilesItemSelect) {
      activeIds = myFilesMultiSelect
          .filter((item) => item.objectType === 'item')
          .map((item) => item.id);
    } else {
      activeIds = [activeDragData?.activeId];
    }
    // Add Item to Jots Page
    addItemToPage({
      activeId: activeDragData?.activeId,
      multiSelectArray: activeIds,
      pageId: response?.data?.id,
      isMultipleSelectScenario: activeDragData?.isMultipleMyFilesItemSelect,
      from: CONTAINER_TYPES.MY_FILES,
    });
    // Update Size
    updateItemSize({
      containerArray: boardDetails,
      activeIds,
      overId: response?.data?.id,
      overContainer: CONTAINER_TYPES.JOTS,
    });
    // Remove Item From MyFiles
    removeItemFromMyFilesHelper({
      activeIds,
      isMultipleMyFilesItemSelect:
      activeDragData?.isMultipleMyFilesItemSelect,
      overContainer: overDragData?.overContainer,
      toPage: true,
    });

    // API Call
    moveAPI({
      activeIds,
      parentId: activeDragData?.activeContainerId,
      targetId: response?.data?.id,
      extractName: {
        ...activeDragData?.extractName,
        destinationName: response?.data?.name,
      },
      overDocument: true,
      targetContainer: CONTAINER_TYPES.JOTS,
    });
  }

};