const actions = {
  GET_SHARED_SPARKS: 'GET_SHARED_SPARKS',
  GET_SHARED_SPARKS_LIST_SUCCESS: 'GET_SHARED_SPARKS_LIST_SUCCESS',
  GET_SHARED_SPARKS_SUCCESS: 'GET_SHARED_SPARKS_SUCCESS',
  GET_SHARED_SPARKS_FAILURE: 'GET_SHARED_SPARKS_FAILURE',
  PAUSE_SHARED_SPARKS: 'PAUSE_SHARED_SPARKS',
  PAUSE_LATEST_SHARED_SPARKS: 'PAUSE_LATEST_SHARED_SPARKS',
  RESUME_LATEST_SHARED_SPARKS: 'RESUME_LATEST_SHARED_SPARKS',
  DELETE_LATEST_SHARED_SPARKS: 'DELETE_LATEST_SHARED_SPARKS',
  SHARED_SPARK_ICONS_LOADER: 'SHARED_SPARK_ICONS_LOADER',
  PAUSE_RESUME_LATEST_SHARED_SPARKS_SUCCESS: 'PAUSE_LATEST_SHARED_SPARKS_SUCCESS',
  DELETE_LATEST_SHARED_SPARKS_SUCCESS: 'DELETE_LATEST_SHARED_SPARKS_SUCCESS',
  SET_SHARED_SPARKS_VIEW: 'SET_SHARED_SPARKS_VIEW',
  RESUME_SHARED_SPARKS: 'RESUME_SHARED_SPARKS',
  ARCHIVE_SHARED_SPARKS: 'ARCHIVE_SHARED_SPARKS',
  UNARCHIVE_SHARED_SPARKS: 'UNARCHIVE_SHARED_SPARKS',
  DELETE_SHARED_SPARK: 'DELETE_SHARED_SPARK',
  DELETE_SHARED_SPARK_SUCCESS: 'DELETE_SHARED_SPARK_SUCCESS',
  SHARE_MODAL_VISIBLE: 'SHARE_MODAL_VISIBLE',
  SET_EXPAND_SHARED_SPARK_DETAILS: 'SET_EXPAND_SHARED_SPARK_DETAILS',
  SET_SORT_BY_VALUE_SHARED_SPARKS: 'SET_SORT_BY_VALUE_SHARED_SPARKS',
  GET_URL_PREVIEW: 'GET_URL_PREVIEW',
  GET_URL_PREVIEW_SUCCESS: 'GET_URL_PREVIEW_SUCCESS',
  GET_URL_PREVIEW_FAILURE: 'GET_URL_PREVIEW_FAILURE',
  SET_SELECTED_ID: 'SET_SELECTED_ID',
  DELETE_SHARED_SPARK_MODAL_VISIBLE: 'DELETE_SHARED_SPARK_MODAL_VISIBLE',
  SHOW_NODE_IN_DOM: 'SHOW_NODE_IN_DOM',
  SHARE_SPARK_SUCCESS: 'SHARE_SPARK_SUCCESS',
  PAUSE_SHARED_SPARKS_SUCCESS: 'PAUSE_SHARED_SPARKS_SUCCESS',
  RESUME_SHARED_SPARKS_SUCCESS: 'RESUME_SHARED_SPARKS_SUCCESS',
  ARCHIVE_SHARED_SPARKS_SUCCESS: 'ARCHIVE_SHARED_SPARKS_SUCCESS',
  UNARCHIVE_SHARED_SPARKS_SUCCESS: 'UNARCHIVE_SHARED_SPARKS_SUCCESS',
  RESUME_SHARED_SPARKS_FAILURE: 'RESUME_SHARED_SPARKS_FAILURE',
  DELETE_SHARED_SPARK_FAILURE: 'DELETE_SHARED_SPARK_FAILURE',
  UPDATE_DELETED_SHARED_SPARK: 'UPDATE_DELETED_SHARED_SPARK',
  ASYNC_UPDATE_SPARK_HEADER_IMAGE: 'ASYNC_UPDATE_SPARK_HEADER_IMAGE',
  UPDATE_COPY_LINK: 'UPDATE_COPY_LINK',
  SHARE_SPARK_DETAILS: 'SHARE_SPARK_DETAILS',
  SHARE_SPARK_LOADER: 'SHARE_SPARK_LOADER',
  SHARE_MODAL: 'SHARE_MODAL',
  SHARE_OBJECT: 'SHARE_OBJECT',
  SHARE_OBJECT_SUCCESS: 'SHARE_OBJECT_SUCCESS',
  SHARE_OBJECT_FAILURE: 'SHARE_OBJECT_FAILURE',
  RESET_SPARK_MODAL: 'RESET_SPARK_MODAL',
  SHARED_SPARK_DELETE_MODAL: 'SHARED_SPARK_DELETE_MODAL',
  RESET_SHARED_SPARK_DELETE_MODAL: 'RESET_SHARED_SPARK_DELETE_MODAL',
  EMPTY_SHARE_ALERT_VISIBILITY: 'EMPTY_SHARE_ALERT_VISIBILITY',
  UPDATE_PAUSED_STATUS_PREVIEW_MODAL: 'UPDATE_PAUSED_STATUS_PREVIEW_MODAL',
  GET_SHARED_BOARDS: 'GET_SHARED_BOARDS',
  RESET_SHARED_SPARKS: 'RESET_SHARED_SPARKS',
  GET_SHARED_LIST: 'GET_SHARED_LIST',
  GET_SHARED_LIST_SUCCESS: 'GET_SHARED_LIST_SUCCESS',
  LIST_VIEW_PAUSE_RESUME_LATEST_SHARED_SPARKS_SUCCESS: 'LIST_VIEW_PAUSE_RESUME_LATEST_SHARED_SPARKS_SUCCESS',
  LIST_VIEW_DELETE_LATEST_SHARED_SPARKS_SUCCESS: 'LIST_VIEW_DELETE_LATEST_SHARED_SPARKS_SUCCESS',
  GET_SHARED_PAGINATION_LIST: 'GET_SHARED_PAGINATION_LIST',
  GET_SHARED_PAGINATION_LIST_SUCCESS: 'GET_SHARED_PAGINATION_LIST_SUCCESS',
  PAUSE_RESUME_SHARING: 'PAUSE_RESUME_SHARING',
  PAUSE_RESUME_SHARING_SUCCESS: 'PAUSE_RESUME_SHARING_SUCCESS',
  PAUSE_RESUME_SHARING_FAILURE: 'PAUSE_RESUME_SHARING_FAILURE',
  SET_SHARABLE_FILTER: 'SET_SHARABLE_FILTER',
};

export default actions;
