import React from 'react';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import actions from 'redux/auth/actions';
import { postRequest, getRequest, putRequest } from 'utility/axiosClient';
import {
  handleException,
  getUserId,
  alertView,
  infoBannerMessageRemove,
  setCookie,
  deleteCookie,
  calculateNetworkUploadSpeed,
  onSignOutModalVisibleUpdate, setActiveTabs,
} from 'utility/helpers';
import { updateAwsSdk, updateHistory } from 'utility/helpers';
import { Translate, uploadMedia } from 'utility/helpers';
import { store } from 'redux/store';
import commonActions from 'redux/common/actions';
import {
  removeAsyncAction,
  resetAsyncList,
  retryAsync,
} from 'utility/asyncHelper';
import settings from 'config/settings';
import {
  destroyPusherConnection,
  initiatePusherService,
} from 'utility/pusher/pusherConfiguration';
import boardGroupActions from 'redux/boardGroups/actions';
import subscriptionActions from 'redux/subscription/actions';
import postHog from 'posthog-js';
import AuthAction from 'redux/auth/actions';
import Bugsnag from '@bugsnag/js';
import { handleTourModalVisibility } from '../../components/tour/helper';
import planActions from 'redux/premiumPlans/actions';
import {
  setPremiumPlanHelperModalVisibility,
  setPremiumPlanIndexModalVisibility,
} from 'components/premiumPlan/helpers/utility';
import { FREE_FOREVER_WELCOME_MODAL } from 'components/premiumPlan/helpers/constants';
import {tourCompletedStep} from "../../components/tour/constants";
export function* loginUser(params) {
  try {
    const response = yield call(() => postRequest('login', params.payload)),
      { data } = response;
    localStorage.removeItem('sp_chat_history');
    localStorage.removeItem('sp_chatbot_user_profile');
    localStorage.setItem('sp_chatbot_user_id', response?.data?.id);
    localStorage.setItem('sp_chatbot_user_email', params?.payload?.email);
    document.dispatchEvent(new CustomEvent('sp-close-chat'));
    if (!data.is_email_verified) {
      yield put({
        type: actions.IMMEDIATE_VERIFY_MODAL_VISIBLE,
        payload: true,
        isFromLogin: true,
      });
      yield put({ type: actions.LOGIN_USER_FAILURE });
    } else {
      updateAwsSdk(data.s3_upload_credentials);
      yield put({
        type: actions.LOGIN_USER_SUCCESS,
        payload: data,
      });
      yield put({
        type: actions.GET_USER_DETAILS,
        isFromRegisterPanel: true,
        isFromLoginPanel: true,
      });
      if (data.deactivated) {
        updateHistory('/app/settings/account-settings');
      }
    }
    window.analytics && window.analytics.track('Signed In', { Platform: 'Web App' });
  } catch (error) {
    const badCredentialsResponse = error?.response?.data;
    yield put({
      type: actions.LOGIN_USER_FAILURE,
      payload: badCredentialsResponse,
    });
    if (badCredentialsResponse?.attempt_tried > 2) {
      yield put({
        type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
        module:
          badCredentialsResponse?.attempt_tried > 4
            ? 'badCredentials'
            : 'invalidPassword',
        payload: {
          visible: true,
        },
      });
    } else {
      handleException(error);
    }
  }
}
export function* loginUserSocial(params) {
  try {
    const response = yield call(() =>
        postRequest('social/login', params.payload),
      ),
      { data } = response;
    if (
      data.is_email_verified === false &&
      data.remaining_verification_minutes === null
    ) {
      yield put({ type: actions.VERIFY_MODAL_VISIBLE, payload: true });
      yield put({ type: actions.LOGIN_USER_FAILURE });
    } else {
      updateAwsSdk(data.s3_upload_credentials);
      yield put({
        type: actions.LOGIN_USER_SUCCESS,
        payload: data,
      });
      yield put({ type: actions.GET_USER_DETAILS, isFromRegisterPanel: true, hasToReloadPage: true });
      if (data.deactivated) {
        updateHistory('/app/settings/account-settings');
      }
    }
    window.analytics && window.analytics.track('Signed In', { Platform: 'Web App' });
  } catch (error) {
    yield put({ type: actions.LOGIN_USER_FAILURE });
    yield window.history.replaceState(
      {},
      null,
      `${window.location.origin}${window.location.pathname}`,
    );
    handleException(error);
  }
}

export function* registerUser(params) {
  try {
    const response = yield call(() => postRequest('register', params.payload)),
      { data } = response;
    if (params?.plansCouponId) {
      yield put({
        type: planActions.CHECKOUT_TO_CHARGEBEE,
        planId: params.planId || 'pay_now_annual_subscription',
        plansIndexModalVisibility: false,
        payload: {
          platform: 'web',
          coupon_id: params.plansCouponId,
        },
      });
    }
    yield put({
      type: actions.REGISTER_USER_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.SHOW_REGISTRATION_FORM,
      payload: '',
      visibility: false,
    });
    yield put({
      type: actions.IMMEDIATE_VERIFY_MODAL_VISIBLE,
      payload: true,
      isFromLogin: false,
    });
    //NOTE: We're tracking adjust events in backend
    // window.Adjust.trackEvent({
    //   eventToken: adjustEvents.sign_up[appEnvironment],
    // });
    window.analytics && window.analytics.track('Signed Up', { Platform: 'Web App' });
  } catch (error) {
    yield put({ type: actions.REGISTER_USER_FAILURE });
    yield put({
      type: actions.SHOW_REGISTRATION_FORM,
      payload: '',
      visibility: false,
    });
    handleException(error);
  }
}
export function* registerUserSocial(params) {
  try {
    const response = yield call(() =>
        postRequest('social/register', params.payload),
      ),
      { data } = response;
    yield put({
      type: actions.REGISTER_USER_SUCCESS,
      payload: data,
    });
    yield put({
      type: actions.SHOW_REGISTRATION_FORM,
      payload: '',
      visibility: false,
    });
    yield put({
      type: actions.GET_USER_DETAILS,
      isFromRegisterPanel: true,
    });
    //NOTE: We're tracking adjust events in backend
    // window.Adjust.trackEvent({
    //   eventToken: adjustEvents.sign_up[appEnvironment],
    // });
    window.analytics && window.analytics.track('Signed Up', { Platform: 'Web App' });
  } catch (error) {
    yield put({ type: actions.REGISTER_USER_FAILURE });
    yield window.history.replaceState(
      {},
      null,
      `${window.location.origin}${window.location.pathname}`,
    );
    yield put({
      type: actions.SHOW_REGISTRATION_FORM,
      payload: '',
      visibility: false,
    });
    handleException(error);
  }
}

export function* forgotPassword(params) {
  try {
    yield call(() => postRequest('forgot/web', params.payload));
    yield put({
      type: actions.FORGOT_PASSWORD_SUCCESS,
    });
    yield put({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'forgotPassword',
      payload: {
        forgotPasswordInfoModalVisible: true,
      },
    });
  } catch (error) {
    yield put({ type: actions.FORGOT_PASSWORD_FAILURE });
    handleException(error);
  }
}

export function* resetPassword(params) {
  try {
    yield call(() => postRequest('reset-password', params.payload));
    yield put({
      type: actions.RESET_PASSWORD_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.RESET_PASSWORD_FAILURE });
    handleException(error);
  }
}

export function* logoutUser(params) {
  try {
    deleteCookie('user_name_organyz');
    yield call(() => postRequest('logout', params.payload));
    window.analytics && window.analytics.track('Signed Out', { Platform: 'Web App' });
    window.analytics && window.analytics.reset();
    notification.destroy();
    localStorage.removeItem(`node-${getUserId()}`);
    localStorage.removeItem('activeTab');
    localStorage.removeItem('searchHistory');
    localStorage.removeItem('userId');
    localStorage.removeItem('sp_chat_history');
    localStorage.removeItem('sp_chatbot_user_profile');
    localStorage.removeItem('sp_chatbot_user_id');
    localStorage.removeItem('sp_chatbot_user_email');
    onSignOutModalVisibleUpdate(false);
    window.location.reload();
  } catch (error) {
    yield put({ type: actions.LOGOUT_USER_FAILURE });
    handleException(error);
  }
}

export function* refreshToken(params) {
  try {
    const { id, uploadMediaData, isFromBoot, isFromDeactiveAccount } = params,
      response = yield call(() => postRequest('refresh-token')),
      { data } = response;
    if (!data.is_email_verified) {
      yield put({ type: actions.LOGOUT_USER });
    } else {
      yield put({
        type: actions.REFRESH_TOKEN_SUCCESS,
        payload: data,
      });
      yield updateAwsSdk(data.s3_upload_credentials);
      if (uploadMediaData) {
        uploadMedia(
          uploadMediaData.payload,
          uploadMediaData.successCallback,
          uploadMediaData.failureCallback,
        );
      }
      calculateNetworkUploadSpeed();
      if (isFromBoot || isFromDeactiveAccount) {
        yield put({ type: actions.GET_USER_DETAILS });
      }
      if (data.deactivated) {
        updateHistory('/app/settings/account-settings');
      }
    }
    if (id) {
      removeAsyncAction(id);
    }
  } catch (error) {

    // TODO: Handled the API cancel case which leads to Logout
    if (error.code !== 'ECONNABORTED') {
      yield put({ type: actions.REFRESH_TOKEN_FAILURE });
      destroyPusherConnection();
      resetAsyncList();
      window.analytics && window.analytics.reset();
    }
  }
}

export function* getUserDetails(params) {
  try {
    const response = yield call(() => getRequest('users/me', params.payload));
    const entryPanelVisibilityLocalStorage = localStorage.getItem('entryPanelStatus');
    localStorage.setItem('app-theme', response.data?.app_theme || 'grey');
    localStorage.setItem('isLoggedIn', 'true');
    if (
      response.data?.onboarding_state === 1 &&
      !response?.data?.has_completed_tour &&
      localStorage.getItem('tour-current-step') === null
    ) {
      setActiveTabs(['activities', 'capture', 'home']);
      handleTourModalVisibility(true);
    }
    if (
      entryPanelVisibilityLocalStorage === 'true' &&
      params?.isFromLoginPanel &&
      response?.data?.has_completed_tour
    ) {
      store.dispatch({
        type: AuthAction.ENTRY_PANEL_VISIBILITY,
        payload: true,
      });
    }
    if (response.data?.scheduled_free_forever_modal_status) {
      setPremiumPlanHelperModalVisibility({
        visibility: FREE_FOREVER_WELCOME_MODAL,
      });
    }
    if (response.data?.plan_model_visibility) {
      yield put({
        type: planActions.GET_LATEST_PLANS_LIST,
      });
    }
    initiatePusherService(response.data.user_id);
    postHog.identify(response.data.user_id, {
      email: response?.data?.email,
    });
    Bugsnag.setUser(response?.data?.email);
    const fullName = `${response.data?.first_name} ${response.data?.last_name}`;
    setCookie('user_name_organyz', fullName, 1);
    const planNature = new URLSearchParams(window.location.search).get(
      'plan_nature',
    );
    if (response?.data?.avatar) {
      localStorage.setItem('sp_chatbot_user_profile', response?.data?.avatar);
    }
    localStorage.setItem('sp_chatbot_user_id', response?.data?.user_id);
    localStorage.setItem('sp_chatbot_user_email', response?.data?.email);
    yield put({
      type: actions.GET_USER_DETAILS_SUCCESS,
      payload: response.data,
      isFromRegisterPanel: params?.isFromRegisterPanel,
    });
    yield put({
      type: actions.GET_ACTIVE_SESSIONS,
    });
    if (response.data.deactivated) {
      updateHistory('/app/settings/account-settings');
    }
    if (
      response.data?.plan_id === '' &&
      response.data?.chosen_plan_id !== null &&
      params?.isFromRegisterPanel
    ) {
      yield put({
        type: actions.UPDATE_USER_VERIFICATION_PROCESS,
      });
      yield put({
        type: subscriptionActions.SELECT_PLAN,
        payload: {
          planId: response?.data?.chosen_plan_id,
          planNature: planNature,
        },
      });
    } else if (
      response.data?.plan_id === '' ||
      response.data?.plan_id === null
    ) {
      yield put({
        type: actions.UPDATE_USER_VERIFICATION_PROCESS,
      });
      if (params?.isFromRegisterPanel) {
        window.location.href = `${settings.REACT_APP_WEB_APP_URL}settings/plans`;
      }
      updateHistory('/app/settings/plans');
    } else {
      if (params?.isFromRegisterPanel) {
        yield put({
          type: actions.UPDATE_USER_VERIFICATION_PROCESS,
        });
        if (params?.hasToReloadPage) {
          window.location.href = settings.REACT_APP_WEB_APP_URL;
        } else {
          updateHistory('/app');
        }
      }
    }
    if (
      response.data.revision_permissions.sync_limit !== null &&
      response.data.no_of_active_session >
        response.data.revision_permissions.sync_limit
    ) {
      // Need to Recheck the setTimeOut flow
      setTimeout(() => {
        updateHistory('/app/settings/active-sessions');
      }, 1000);
      yield put({
        type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
        module: 'upgradeModal',
        payload: {
          upgradeModalVisible: true,
          upgradeModalMessage: <Translate>active_session_limit</Translate>,
          upgradeModalLogOut: false,
        },
      });
    }
  } catch (error) {
    if (error?.response?.status !== 401 && !params.isFromRegisterPanel) {
      yield put({ type: actions.GET_USER_DETAILS_FAILURE });
    }
    if (!params.isFromRegisterPanel) {
      handleException(error, params);
    }
  }
}

export function* resendOtp(params) {
  try {
    yield call(() => getRequest('users/me/verify'));
    yield put({
      type: actions.VERIFY_MODAL_VISIBLE,
      payload: false,
    });
    yield put({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'resendLinkModal',
      payload: {
        visible: true,
      },
    });
  } catch (error) {
    handleException(error, params);
  }
}

export function* fetchDeactivateReasonList(params) {
  try {
    const response = yield call(() => getRequest('users/deactivate/reasons'));
    yield put({
      type: actions.FETCH_DEACTIVATE_REASON_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    handleException(error, params);
  }
}
export function* fetchDeleteAccountReasonList(params) {
  try {
    const response = yield call(() =>
      getRequest('users/delete_account/reasons'),
    );
    yield put({
      type: actions.FETCH_DELETE_ACCOUNT_REASON_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    handleException(error, params);
  }
}
export function* deactivateAccount(params) {
  try {
    let { payload, otherReasonsId } = params,
      data = {
        reasons: [],
        password: payload.password,
      };
    payload.values.map((reason) =>
      reason === otherReasonsId
        ? data.reasons.push({ id: reason, reason: payload.comments })
        : data.reasons.push({ id: reason }),
    );
    yield call(() => postRequest('users/me/deactivate', data));
    yield put({ type: actions.DEACTIVATE_ACCOUNT_SUCCESS });
    yield put({
      type: actions.DEACTIVATE_CONFIRMATION_VISIBLE,
      payload: false,
    });
    yield put({ type: actions.REACTIVATE_CONFIRMATION_VISIBLE, payload: true });
    yield put({
      type: actions.REFRESH_TOKEN,
      isFromDeactiveAccount: true,
    });
  } catch (error) {
    handleException(error, params);
    yield put({ type: actions.DEACTIVATE_ACCOUNT_FAILURE });
  }
}

export function* updateUserDetails(params) {
  try {
    const response = yield call(() => putRequest('users/me', params.payload));
    yield put({
      type: actions.UPDATE_USER_DETAILS_SUCCESS,
      payload: response.data,
    });
    yield put({ type: actions.CHANGE_PASSWORD_VISIBLE, payload: false });
    if (params.isChangePassword) {
      yield put({
        type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
        module: 'changePasswordModal',
        payload: {
          changePasswordModalVisible: true,
        },
      });
      //NOTE: Check with the committed author if possible and if needed.
      //CHECKME: Commented for Performance optimization - It looks like an unwanted API calls made for fetching the existing details
      // yield put({
      //   type: actions.GET_ACTIVE_SESSIONS,
      // });
      localStorage.setItem('settingsOpenedKeys', 'data');
      updateHistory('/app/settings/active-sessions');
      if (document.getElementById('infoBanner')) {
        document.getElementById('infoBanner').remove();
      }
    } else {
      alertView({
        parentContainer: 'settings-container',
        alertText: 'profile_update_success',
        type: 'success',
        delay: 3,
      });
    }
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({ type: actions.UPDATE_USER_DETAILS_FAILURE });
    }
    handleException(error, params);
  }
}
export function* asyncUpdateUserDetails(params) {
  const {
    payload: { payload },
    id,
  } = params;
  try {
    yield call(() => putRequest('users/me', payload));
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* activateAccount(params) {
  try {
    const response = yield call(() => postRequest('users/me/activate'));
    yield put({
      type: actions.ACTIVATE_ACCOUNT_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: actions.REACTIVATE_CONFIRMATION_VISIBLE,
      payload: false,
    });
    yield put({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'reactivateAccountModal',
      payload: {
        reactivateAccountModalVisible: true,
      },
    });
    yield infoBannerMessageRemove();
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({ type: actions.ACTIVATE_ACCOUNT_FAILURE });
    }
    handleException(error, params);
  }
}

export function* changeUserAvatar(params) {
  try {
    const response = yield call(() => postRequest('me/avatar', params.payload));
    yield put({
      type: actions.CHANGE_USER_AVATAR_SUCCESS,
      payload: response.data,
    });
    if (response?.data?.avatar) {
      localStorage.setItem('sp_chatbot_user_profile', response?.data?.avatar);
    }
    if (!params?.isFromSpark) {
      alertView({
        parentContainer: 'settings-container',
        alertText: 'user_avatar_success',
        type: 'success',
        delay: 3,
      });
    }
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({ type: actions.CHANGE_USER_AVATAR_FAILURE });
    }
    handleException(error, params);
  }
}

export function* onBoardComplete(params) {
  try {
    yield call(() => postRequest('users/update_onboard_process'));
    yield put({
      type: actions.SET_ON_BOARDING_MODAL_VISIBLE,
      visible: false,
      isOnboardComplete: true,
    });
    updateHistory('/');
  } catch (error) {
    handleException(error);
  }
}

export function* locationStatusChange(params) {
  const {
    payload: { status },
    id,
  } = params;
  try {
    yield call(() => postRequest('users/update_location_access', status));
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* getPasswordsList(params) {
  try {
    const response = yield call(() => getRequest('show_common_password_list'));
    yield put({
      type: actions.GET_PASSWORDS_LIST_SUCCESS,
      passwordsList: response.data,
    });
  } catch (error) {
    handleException(error, params);
  }
}

export function* getActiveSessions(params) {
  try {
    const response = yield call(() => getRequest('users/session'));
    yield put({
      type: actions.GET_ACTIVE_SESSIONS_SUCCESS,
      activeSessions: response.data,
    });
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({
        type: actions.GET_ACTIVE_SESSIONS_FAILURE,
      });
    }
    handleException(error, params);
  }
}

export function* revokeAllSessions(params) {
  try {
    yield call(() => postRequest('users/session/delete-all-sessions'));
    yield put({
      type: actions.GET_ACTIVE_SESSIONS,
    });
    yield put({
      type: actions.UPDATE_ACTIVE_SESSION_DETAILS,
      payload: 1,
    });
  } catch (error) {
    handleException(error, params);
  }
}

export function* revokeSpecificSession(params) {
  const { noOfActiveSession } = store.getState().Auth;
  try {
    yield call(() => postRequest('users/session/delete', params));
    yield put({
      type: actions.REVOKE_SPECIFIC_SESSION_SUCCESS,
      revokedSessionId: params.session_ids[0],
    });
    yield put({
      type: actions.UPDATE_ACTIVE_SESSION_DETAILS,
      payload: noOfActiveSession - 1,
    });
  } catch (error) {
    if (error?.response?.status !== 401) {
      yield put({
        type: actions.REVOKE_SPECIFIC_SESSION_FAILURE,
      });
    }
    handleException(error, params);
  }
}

export function* setSharedSparkExpand(params) {
  try {
    yield call(() =>
      putRequest('users/update_shared_spark_document_view', params.payload),
    );
  } catch (error) {
    handleException(error, params);
  }
}

export function* setDeviceLanguage(params) {
  try {
    yield call(() => postRequest('users/update_user_language', params.payload));
  } catch (error) {
    handleException(error);
  }
}
export function* setLastAccessedPage(params) {
  const { payload, id } = params;
  try {
    yield call(() =>
      putRequest('users/update_last_accessed_page_details', payload),
    );
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}
export function* setShowTipsMessage(params) {
  const { payload, id } = params;
  try {
    yield call(() => postRequest('user/update-tip-messages', payload));
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* setShowAllTipsMessage(params) {
  const { payload, id } = params;
  try {
    yield call(() => postRequest('user/update-show-all-tip-key', payload));
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}


export function* onUpdateAppTheme(params) {
  const { payload } = params;
  try {
    yield call(() => postRequest('users/update_app_theme', payload));
  } catch (error) {
    handleException(error, params);
  }
}

export function* deleteAccount(params) {
  try {
    let { payload, otherReasonsId, scheduledDelete = false } = params,
      data = {
        reasons: [],
        password: payload.password,
        download_assets: payload.download_assets,
        is_scheduled_delete: payload?.is_scheduled_delete,
      };
    payload.values.map((reason) =>
      reason === otherReasonsId
        ? data.reasons.push({ id: reason, reason: payload.comments })
        : data.reasons.push({ id: reason }),
    );
    yield call(() => postRequest('users/me/delete_account', data));
    yield put({ type: actions.DELETE_ACCOUNT_SUCCESS });
    yield put({ type: actions.CONTINUE_DELETE_MODAL, payload: false });
    if (scheduledDelete) {
      setPremiumPlanHelperModalVisibility({
        visibility: false,
      });
      setPremiumPlanIndexModalVisibility({
        visibility: false,
      });
      updateHistory('/app/settings/account-settings');
      yield put({
        type: planActions.GET_LATEST_PLANS_LIST,
        payload: {
          preventOpen: true,
          isFromSettingsPage: true,
        },
      });
    } else {
      yield put({
        type: actions.LOGOUT_USER_SUCCESS,
        accountDeletedVisible: true,
      });
      yield put({
        type: actions.ACCOUNT_DELETED_MODAL,
        payload: true,
      });
    }
  } catch (error) {
    handleException(error, params);
    yield put({ type: actions.DELETE_ACCOUNT_FAILURE });
  }
}

export function* validateRegisterEmail(params) {
  const { checkEmailFailure } = store.getState().Auth;
  try {
    const response = yield call(() =>
      postRequest('check-email', params.payload),
    );
    const { data } = response;
    if (!('is_email_verified' in data)) {
      yield put({
        type: actions.SHOW_REGISTRATION_FORM,
        payload: params.payload,
        visibility: true,
      });
    } else if (!data?.is_email_verified) {
      yield put({
        type: actions.REGISTER_USER_SUCCESS,
      });
    }
    if (checkEmailFailure) {
      yield put({
        type: actions.VALIDATE_REGISTER_EMAIL_FAILURE,
        error: '',
      });
    }
  } catch (error) {
    if (error.response.status === 422) {
      yield put({
        type: actions.VALIDATE_REGISTER_EMAIL_FAILURE,
        error: error.response.data,
      });
    }
  }
}

export function* updateOpenAPIDetails(params) {
  try {
    yield call(() => postRequest('openai/update_key', params.payload));
    yield put({
      type: actions.UPDATE_OPEN_API_KEY_SUCCESS,
      payload: params.payload,
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_OPEN_API_KEY_FAILURE,
    });
  }
}

export function* handleOnBoarding(params) {
  try {
    yield call(() => postRequest('v4/user/preload-submit', params.payload));
    yield put({
      type: boardGroupActions.GET_ALL_GROUPS_LIST,
      fromPreLoad: true,
    });
    yield put({
      type: boardGroupActions.GET_BOARD_GROUPS_LIST,
      fromPreLoad: true,
    });
    yield put({
      type: actions.UPDATE_ON_BOARDING_STATUS_SUCCESS,
    });
    yield put({
      type: actions.INVOKE_PRE_NAMED_CONFIG_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_ON_BOARDING_STATUS_FAILURE,
    });
  }
}

export function* updateOnboardingTourSteps(params) {
  try {
    let payload = { onboarding_step: params.payload };
    if(params.payload === tourCompletedStep) {
        payload = { onboarding_step: params.payload, has_completed_tour: true }
    }
    yield call(() =>
      postRequest('user/onboarding-step', payload),
    );
  } catch (error) {
    handleException(error, params);
  }
}
// eslint-disable-next-line
export function* setDontShowEmptyContainerModal(params) {
  try {
    const payload = {
      show_hide_empty_modal: !params?.payload
    }
    yield call(() => postRequest('v4/user/show-hide-empty-modal', payload));
  } catch (error) {
    handleException(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN_USER, loginUser),
    takeEvery(actions.LOGIN_USER_SOCIAL, loginUserSocial),
    takeEvery(actions.REGISTER_USER, registerUser),
    takeEvery(actions.REGISTER_USER_SOCIAL, registerUserSocial),
    takeEvery(actions.FORGOT_PASSWORD, forgotPassword),
    takeEvery(actions.RESET_PASSWORD, resetPassword),
    takeEvery(actions.LOGOUT_USER, logoutUser),
    takeEvery(actions.REFRESH_TOKEN, refreshToken),
    takeEvery(actions.GET_USER_DETAILS, getUserDetails),
    takeEvery(actions.RESEND_OTP, resendOtp),
    takeEvery(actions.FETCH_DEACTIVATE_REASON_LIST, fetchDeactivateReasonList),
    takeEvery(
      actions.FETCH_DELETE_ACCOUNT_REASON_LIST,
      fetchDeleteAccountReasonList,
    ),
    takeEvery(actions.DEACTIVATE_ACCOUNT, deactivateAccount),
    takeEvery(actions.UPDATE_USER_DETAILS, updateUserDetails),
    takeEvery(actions.ASYNC_UPDATE_USER_DETAILS, asyncUpdateUserDetails),
    takeEvery(actions.ACTIVATE_ACCOUNT, activateAccount),
    takeEvery(actions.CHANGE_USER_AVATAR, changeUserAvatar),
    takeEvery(actions.ON_BOARD_COMPLETE_PROCESS, onBoardComplete),
    takeEvery(actions.SET_LOCATION_ACCESS_STATUS, locationStatusChange),
    takeEvery(actions.GET_PASSWORDS_LIST, getPasswordsList),
    takeEvery(actions.GET_ACTIVE_SESSIONS, getActiveSessions),
    takeEvery(actions.REVOKE_ALL_SESSIONS, revokeAllSessions),
    takeEvery(actions.REVOKE_SPECIFIC_SESSION, revokeSpecificSession),
    takeEvery(actions.SET_SHARED_SPARK_EXPAND_DETAILS, setSharedSparkExpand),
    takeEvery(actions.SET_DEVICE_LANGUAGE, setDeviceLanguage),
    takeEvery(actions.SET_LAST_ACCESSED_PAGE, setLastAccessedPage),
    takeEvery(actions.ASYNC_SET_SHOW_TIPS_STATUS, setShowTipsMessage),
    takeEvery(actions.ASYNC_SET_SHOW_ALL_TIPS_STATUS, setShowAllTipsMessage),
    takeEvery(actions.DELETE_ACCOUNT, deleteAccount),
    takeEvery(actions.VALIDATE_REGISTER_EMAIL, validateRegisterEmail),
    takeEvery(actions.UPDATE_OPEN_API_KEY, updateOpenAPIDetails),
    takeEvery(actions.UPDATE_ON_BOARDING_STATUS, handleOnBoarding),
    takeEvery(actions.UPDATE_ONBOARDING_TOUR_STEPS, updateOnboardingTourSteps),
    takeEvery(actions.UPDATE_APP_THEME, onUpdateAppTheme),
    takeEvery(
      actions.SET_DONT_SHOW_EMPTY_CONTAINER_MODAL,
      setDontShowEmptyContainerModal,
    ),
  ]);
}
