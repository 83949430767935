import {
  boardArchivedContext,
  commonItemsContext,
  documentArchivedContext,
  documentContext,
  folderArchivedContext,
  folderContext,
  groupArchivedContext,
  groupContext,
  sparkContext,
  sparkResumeContext,
  captureItemsContext,
  imageItemsContext,
  videoItemsContext,
  noteItemsContext,
  listItemsContext,
  unknownItemsContext,
  locationItemsContext,
  audioItemsContext,
  contactItemsContext,
  urlItemsContext,
  sparkContextfree,
  tourDocumentContext,
  captureItemsContextWithoutDownLoad, sparkSavedContext,
} from './constant';
import jotsActions from '../../redux/jots/actions';
import boardGroupActions from '../../redux/boardGroups/actions';
import commonActions from '../../redux/common/actions';
import { store } from '../../redux/store';
import {
  getDuplicateNodeName,
  randomIdGenerate,
  downloadItems,
  copyToClipboard,
  Translate,
  updateHistory,
  versionHandling,
  getTimezoneFormat,
  isObjectEmpty,
  alertView,
  deleteAsyncLoaderVisibility,
  setPropertiesLoader,
} from '../../utility/helpers';
import {
  calculatedStorage,
  editDocumentItem,
  editFolderItem,
  editIndividualBoardItem,
  editJotsDocumentItem,
  getUsedStoragePercentage,
  renderAddBar,
} from '../addBar/helpers';
import { addAsyncAction } from '../../utility/asyncHelper';
import sharedSparkActions from 'redux/sharedSparks/actions';
import {
  getDetails,
  isCloudAreaListView,
  updateIndexSection,
} from '../boardGroups/helper';
import { getParentDetails } from '../shared/duplicate/helper';
import capturesActions from 'redux/capture/actions';
import folderActions from '../../redux/folders/actions';
import { cloneDeep } from 'lodash';
import {
  deleteModalVisibility as deleteModalVisibilityModal,
  deleteModalVisibility,
} from '../../utility/deleteConfirmation';
import myFilesAlertView from "../notification/myFilesAlertView";
import addbarActions from "../../redux/addBar/actions";
import { removeItemsFromCaptureHelper } from '../dnd/actionHelper';
import {shareSpark} from "../shareLinks/helper";

export function getContextMenu(itemType) {
  switch (itemType) {
    case 'groupContext':
      return groupContext;
    case 'image': {
      return imageItemsContext;
    }
    case 'video': {
      return videoItemsContext;
    }
    case 'note': {
      return noteItemsContext;
    }
    case 'list': {
      return listItemsContext;
    }
    case 'unknown': {
      return unknownItemsContext;
    }
    case 'location': {
      return locationItemsContext;
    }
    case 'audio': {
      return audioItemsContext;
    }
    case 'contact': {
      return contactItemsContext;
    }
    case 'url': {
      return urlItemsContext;
    }
    default:
      return commonItemsContext;
  }
}

function getCaptureContextMenu(itemType) {
  switch (itemType) {
    case 'location': {
      return captureItemsContextWithoutDownLoad;
    }
    // TODO: contact download need to handle, seems it was missed
    case 'contact': {
      return captureItemsContextWithoutDownLoad;
    }
    case 'url': {
      return captureItemsContextWithoutDownLoad;
    }
    default:
      return captureItemsContext;
  }
}

function shareAsWepPage(id, isHomeActivity, nodeId, groupId, projectId, nodeType, data, parent, itemDetails) {
  const sparkConfig = {
    title: itemDetails?.name,
    description: itemDetails?.description,
    image: itemDetails?.thumbnail,
    url: itemDetails?.url,
  }
  shareSpark({
    shareAs: 'webpage',
    sparkConfig,
    shareId: itemDetails.id,
    url: itemDetails?.url,
    nodeType: itemDetails?.type,
  });
}

function shareAsPDF(id, isHomeActivity, nodeId, groupId, projectId, nodeType, data, parent, itemDetails) {
  const sparkConfig = {
    title: itemDetails?.name,
    description: itemDetails?.description,
    image: itemDetails?.thumbnail,
    url: itemDetails?.url,
  }
  shareSpark({
    shareAs: 'pdf',
    sparkConfig,
    shareId: itemDetails.id,
    url: itemDetails?.shared_link,
    nodeType: itemDetails?.type,
  });
}

export function getContextValue(context, itemType, tourContext) {
  switch (context) {
    case 'groupContext':
      return groupContext;
    case 'folderContext':
      return folderContext;
    case 'documentContext':
      return tourContext ? tourDocumentContext : documentContext;
    case 'itemsContext':
      return getContextMenu(itemType);
    case 'groupArchivedContext':
      return groupArchivedContext;
    case 'boardArchivedContext':
      return boardArchivedContext;
    case 'folderArchivedContext':
      return folderArchivedContext;
    case 'documentArchivedContext':
      return documentArchivedContext;
    case 'boardItemsContext':
      return commonItemsContext;
    case 'documentItemsContext':
      return getContextMenu(itemType);
    case 'individualItemsContext':
      return getContextMenu(itemType);
    case 'jotsIndividualItemsContext':
      return getContextMenu(itemType);
    case 'sparkContext':
      return sparkContext;
    case 'sparkContextfree':
      return sparkContextfree;
    case 'sparkResumeContext':
      return sparkResumeContext;
    case 'sparkContextSaved':
      return sparkSavedContext;
    case 'capturesItemsContext':
      return getCaptureContextMenu(itemType);
    default:
      break;
  }
}

const functionMapping = {
  edit_properties: {
    groupContext: groupEditProperties,
    folderContext: folderEditProperties,
    documentContext: documentEditProperties,
  },
  duplicate: {
    groupContext: groupDuplicate,
    folderContext: folderDuplicate,
    documentContext: documentDuplicate,
    itemsContext: itemsDuplicate,
    folderItemsContext: folderItemsDuplicate,
    documentItemsContext: documentItemsDuplicate,
    individualItemsContext: individualItemsDuplicate,
    jotsIndividualItemsContext: jotsIndividualItemsDuplicate,
  },
  share: {
    groupContext: groupShare,
    folderContext: folderShare,
    documentContext: documentShare,
    jotsIndividualItemsContext: itemShare,
    individualItemsContext: itemShare,
    capturesItemsContext: itemShare,
    documentItemsContext: itemShare,
  },
  archive: {
    groupContext: groupArchive,
    groupArchivedContext: groupArchive,
    folderContext: folderArchive,
    folderArchivedContext: folderArchive,
    documentContext: documentArchive,
    documentArchivedContext: documentArchive,
  },
  unarchive: {
    groupArchivedContext: groupUnArchive,
    folderArchivedContext: folderUnArchive,
    documentArchivedContext: documentUnArchive,
  },
  download: {
    capturesItemsContext: capturesItemDownload,
    jotsIndividualItemsContext: downloadItem,
    documentItemsContext: downloadItem,
    individualItemsContext: downloadItem,
  },
  delete: {
    groupContext: groupDelete,
    groupArchivedContext: archiveGroupDelete,
    folderContext: folderDelete,
    folderArchivedContext: folderArchiveDelete,
    documentContext: documentDelete,
    itemsContext: itemsDelete,
    documentArchivedContext: documentArchiveDelete,
    individualItemsContext: individualItemDelete,
    jotsIndividualItemsContext: individualItemDelete,
    capturesItemsContext: capturesItemDelete,
    documentItemsContext: documentItemDelete,
    sparkContext: sparkDelete,
    sparkContextSaved: sparkDelete,
    sparkResumeContext: sparkDelete,
    boardArchivedContext: archiveBoardDelete,
    sparkContextfree: sparkDelete,
  },
  share_web_page: {
    sparkContextSaved: shareAsWepPage,
  },
  share_pdf: {
    sparkContextSaved: shareAsPDF,
  },
  copy_link: {
    sparkContext: copySparkLink,
    sparkResumeContext: copySparkLink,
    sparkContextfree: copySparkLink,
  },
  pause_sharing: {
    sparkContext: pauseSharingSpark,
  },
  resume_sharing: {
    sparkResumeContext: resumeSharingSpark,
  },
  show_source: {
    sparkContext: showSourceSpark,
    sparkContextSaved: showSourceSpark,
    sparkResumeContext: showSourceSpark,
    sparkContextfree: showSourceSpark,
  },
  image_properties: {
    jotsIndividualItemsContext: jotsIndividualItemProperties,
    documentItemsContext: documentItemProperties,
    individualItemsContext: individualItemProperties,
  },
  video_properties: {
    jotsIndividualItemsContext: jotsIndividualItemProperties,
    documentItemsContext: documentItemProperties,
    individualItemsContext: individualItemProperties,
  },
  properties: {
    jotsIndividualItemsContext: jotsIndividualItemProperties,
    documentItemsContext: documentItemProperties,
    individualItemsContext: individualItemProperties,
  },
  edit_note: {
    jotsIndividualItemsContext: editItem,
    documentItemsContext: editItem,
    individualItemsContext: editItem,
  },
  update_location: {
    jotsIndividualItemsContext: editItem,
    documentItemsContext: editItem,
    individualItemsContext: editItem,
  },
  edit_contact: {
    jotsIndividualItemsContext: editItem,
    documentItemsContext: editItem,
    individualItemsContext: editItem,
  },
  replace: {
    documentItemsContext: replaceImage,
  },
  cut_item: {
    capturesItemsContext: cutCaptureItem,
  },
};

const listViewFunctionMapping = {
  edit_properties: {
    groupContext: listViewGroupEditProperties,
    folderContext: listViewFolderEditProperties,
    documentContext: listViewDocumentEditProperties,
  },
  duplicate: {
    groupContext: listViewGroupDuplicate,
    folderContext: listViewFolderDuplicate,
    documentContext: listViewFolderDuplicate,
    itemsContext: listViewIndividualItemsDuplicate,
    folderItemsContext: folderItemsDuplicate,
    documentItemsContext: listViewDocumentItemsDuplicate,
    individualItemsContext: individualItemsDuplicate,
    jotsIndividualItemsContext: jotsIndividualItemsDuplicate,
  },
  share: {
    groupContext: listGroupShare,
    folderContext: listFolderShare,
    documentContext: listDocumentShare,
    documentItemsContext: listItemShare,
    itemsContext: listItemShare,
  },
  archive: {
    groupContext: listViewGroupArchive,
    groupArchivedContext: groupArchive,
    folderContext: listViewFolderArchive,
    documentContext: listViewFolderArchive,
    folderArchivedContext: folderArchive,
    documentArchivedContext: documentArchive,
  },
  unarchive: {
    groupArchivedContext: listViewGroupUnArchive,
    folderArchivedContext: listViewFolderUnArchive,
    documentArchivedContext: listViewDocumentUnArchive,
  },
  delete: {
    groupContext: listViewGroupDelete,
    groupArchivedContext: listViewGroupDelete,
    folderContext: listViewFolderDelete,
    folderArchivedContext: listViewFolderDelete,
    documentContext: listViewFolderDelete,
    itemsContext: listViewIndividualItemDelete,
    documentArchivedContext: listViewDocumentArchiveDelete,
    individualItemsContext: listViewIndividualItemDelete,
    sparkContext: listSparkDelete,
    sparkResumeContext: listSparkDelete,
    documentItemsContext: listViewDocumentItemsDelete,
    sparkContextfree: listSparkDelete,
  },
  copy_link: {
    sparkContext: listCopySparkLink,
    sparkContextfree: listCopySparkLink,
  },
  pause_sharing: {
    sparkContext: listPauseSharingSpark,
  },
  resume_sharing: {
    sparkResumeContext: listResumeSharingSpark,
  },
  show_source: {
    sparkContext: listShowSourceSpark,
    sparkResumeContext: listShowSourceSpark,
    sparkContextfree: listShowSourceSpark,
  },
  properties: {
    itemsContext: listIndividualItemProperties,
    documentItemsContext: listDocumentItemProperties,
  },
  edit_note: {
    itemsContext: listEditItem,
    documentItemsContext: listEditItem,
  },
  update_location: {
    itemsContext: listEditItem,
    documentItemsContext: listEditItem,
  },
  edit_contact: {
    itemsContext: listEditItem,
    documentItemsContext: listEditItem,
  },
  download: {
    itemsContext: listDownloadItem,
    documentItemsContext: listDownloadItem,
  },
};

function groupEditProperties(id) {
  const { boardGroupsList } = store.getState().BoardGroups;
  const selectedIdDetails = boardGroupsList.find(
    (data) => data.id === id,
  );
  const groupBoardPropertyDetails = {
    name: selectedIdDetails.name,
    description: selectedIdDetails.description,
    groupId: id,
    visibility: true,
    avatar: selectedIdDetails?.avatar,
  };
  store.dispatch({
    type: boardGroupActions.SHOW_GROUP_BOARD_PROPERTY_MODAL,
    groupBoardPropertyDetails,
  });
}
function listViewGroupEditProperties({ id }) {
  const { boardGroupsList } = store.getState().BoardGroups;
  const selectedIdDetails = boardGroupsList.find(
    (data) => data.group_id === id,
  );
  const groupBoardPropertyDetails = {
    name: selectedIdDetails.name,
    description: selectedIdDetails.description,
    groupId: id,
    visibility: true,
    avatar: selectedIdDetails?.avatar,
  };
  store.dispatch({
    type: boardGroupActions.SHOW_GROUP_BOARD_PROPERTY_MODAL,
    groupBoardPropertyDetails,
  });
}

function folderEditProperties(id) {
  const { boardDetails } = store.getState().BoardGroups;
  const selectedIdDetails = boardDetails.folders.find(
    (data) => data.node_id === id,
  );
  const folderPropertyDetails = {
    name: selectedIdDetails.name,
    description: selectedIdDetails.description,
    folderId: id,
    visibility: true,
    avatar: selectedIdDetails?.avatar,
    created_at: selectedIdDetails.created_at
  };
  store.dispatch({
    type: boardGroupActions.SHOW_PROPERTY_MODAL,
    module: 'folderPropertyDetails',
    data: folderPropertyDetails,
  });
}
function listViewFolderEditProperties({ id, itemDetails, data }) {
  const folderPropertyDetails = {
    name: itemDetails.name,
    description: itemDetails.description,
    folderId: id,
    visibility: true,
    avatar: itemDetails?.avatar,
    created_at: data.created_at,
  };
  store.dispatch({
    type: boardGroupActions.SHOW_PROPERTY_MODAL,
    module: 'folderPropertyDetails',
    data: folderPropertyDetails,
  });
}
function listViewDocumentEditProperties({ id, itemDetails, data }) {
  const documentPropertyDetails = {
    name: itemDetails.name,
    description: itemDetails.description,
    folderId: id,
    visibility: true,
    avatar: itemDetails?.avatar,
    created_at: data.created_at,
  };
  store.dispatch({
    type: boardGroupActions.SHOW_PROPERTY_MODAL,
    module: 'documentPropertyDetails',
    data: documentPropertyDetails,
  });
}

export function documentEditProperties(id, isJotsActivity = false) {
  const { boardDetails } = store.getState().BoardGroups;
  const { jotsProjectItems } = store.getState().jotsReducer;
  let selectedIdDetails;
  const { isOpenFullScreenFolderModal } = store.getState().Folder;
  if (isOpenFullScreenFolderModal && isCloudAreaListView() && !isJotsActivity) {
    const { documentParentMeta } = store.getState().commonReducer;
    selectedIdDetails = {
      name: documentParentMeta.name,
      description: documentParentMeta?.description,
    };
  } else {
    const project = isJotsActivity ? jotsProjectItems : boardDetails;
    selectedIdDetails = project.documents.find((data) => data.node_id === id);
  }
  const documentPropertyDetails = {
    name: selectedIdDetails.name,
    description: selectedIdDetails.description,
    folderId: id,
    visibility: true,
    created_at: selectedIdDetails.created_at,
  };
  store.dispatch({
    type: isJotsActivity
      ? jotsActions.SHOW_JOTS_PROPERTY_MODAL
      : boardGroupActions.SHOW_PROPERTY_MODAL,
    module: isJotsActivity
      ? 'jotsDocumentPropertyDetails'
      : 'documentPropertyDetails',
    data: documentPropertyDetails,
  });
}

function groupDuplicate(id) {
  const { boardGroupsList, allGroupList } = store.getState().BoardGroups,
    groupDetail = boardGroupsList.find((data) => data.id === id),
    tempId = randomIdGenerate('group_id');
  const nameList = allGroupList
    ?.map((values) => values.name?.toLowerCase() || false)
    .filter(Boolean);
  const duplicateName = versionHandling(groupDetail.name, nameList);
  const payload = {
    id,
    name: duplicateName,
    version: groupDetail?.version,
    tempId,
    groupId: id,
    parent: null,
    payload: {
      group_id: id,
      name: duplicateName,
    },
    description: groupDetail.description,
    duplicateType: 'group',
    translation: 'group_name',
  };
  duplicateAction(payload);
}

function listViewGroupDuplicate({ id, itemDetails }) {
  const tempId = randomIdGenerate('group_id'),
    duplicateName = getDuplicateNodeName(itemDetails),
    { boardGroupsList } = store.getState().BoardGroups,
    groupDetail = boardGroupsList.find((data) => data.group_id === id);
  const payload = {
    id,
    name: duplicateName,
    version: groupDetail?.version,
    tempId,
    groupId: id,
    parent: null,
    payload: {
      group_id: id,
      name: duplicateName,
    },
    description: groupDetail.description,
    duplicateType: 'group',
    translation: 'group_name',
  };
  duplicateAction(payload);
}

function folderDuplicate(id) {
  const { boardDetails, projectId } = store.getState().BoardGroups,
    documentDetail = boardDetails.folders.find((data) => data.node_id === id);
  const tempId = randomIdGenerate('folder_id'),
    duplicateName = getDuplicateNodeName(documentDetail);
  const duplicateData = {
    nodeId: documentDetail.node_id,
    name: duplicateName,
    version: documentDetail?.version,
    parent: extractProjects(),
    tempId,
    projectId,
    duplicateType: 'folder',
    translation: 'folder_name_title',
    isDocument: false,
    currentParent: getParentDetails(id),
    description: documentDetail.description,
  };
  duplicateAction(duplicateData);
}

function listViewFolderDuplicate({ id, itemDetails }) {
  const tempId = randomIdGenerate('folder_id'),
    projectId = getDetails(id).projectId,
    duplicateName = getDuplicateNodeName(itemDetails),
    duplicateData = {
      nodeId: id,
      name: duplicateName,
      version: itemDetails?.version,
      parent: extractProjects(),
      tempId,
      projectId,
      duplicateType: 'folder',
      translation: 'folder_name_title',
      isDocument: false,
      currentParent: getParentDetails(id),
      description: itemDetails.description,
    };
  duplicateAction(duplicateData);
}
function documentDuplicate(id, isHomeActivity) {
  const { isOpenFullScreenFolderModal } = store.getState().Folder;
  if (isOpenFullScreenFolderModal && isCloudAreaListView() && !isHomeActivity) {
    const { documentParentMeta } = store.getState().commonReducer;
    listViewFolderDuplicate({
      id,
      itemDetails: {
        nodeId: documentParentMeta.node_id,
        projectId: documentParentMeta.project_id,
        name: documentParentMeta.name,
      },
    });
    store.dispatch({
      type: folderActions.OPEN_FULL_SCREEN_FOLDER_MODAL,
      payload: false,
    });
  } else {
    let documentDetail;
    if (isHomeActivity) {
      const { jotsProjectItems } = store.getState().jotsReducer;
      documentDetail = jotsProjectItems.documents.find(
        (data) => data.node_id === id,
      );
    } else {
      // Todo: logics for boards
      const { boardDetails } = store.getState().BoardGroups;
      documentDetail = boardDetails.documents.find(
        (data) => data.node_id === id,
      );
    }
    if (!documentDetail?.is_folder_archived) {
      const tempId = randomIdGenerate('node');
      const duplicateData = {
        nodeId: documentDetail.node_id,
        name: getDuplicateNodeName(documentDetail),
        version: documentDetail.version,
        projectId: documentDetail.project_id,
        tempId,
        lock: documentDetail.lock,
        folderName: documentDetail.name,
        isFolderUpdate: true,
        isHomeActivity,
        parent: extractProjects(isHomeActivity),
        duplicateType: 'document',
        translation: 'document_name_title',
        isDocument: true,
        currentParent: getParentDetails(id, isHomeActivity),
        description: documentDetail.description,
      };
      duplicateAction(duplicateData);
    }
  }
}

function itemsDuplicate(id, isHomeActivity) {
  let itemDetails;
  const { revisionPermissions, storageLimit, used } = store.getState().Auth;
  if (isHomeActivity) {
    const { jotsProjectItems } = store.getState().jotsReducer;
    itemDetails = jotsProjectItems.items.find((data) => data.id === id);
  }
  let addedSizeValueOfAllItems =
    (parseInt(itemDetails?.content?.size) || 0) + used;
  calculatedStorage(
    getUsedStoragePercentage(parseInt(itemDetails?.content?.size)),
  );
  if (['image', 'video', 'audio', 'unknown'].includes(itemDetails.type)) {
    addedSizeValueOfAllItems += parseInt(itemDetails.content.size);
  }
  if (
    !['image', 'video', 'audio', 'unknown'].includes(itemDetails.type) ||
    (itemDetails?.content?.size < storageLimit &&
      (revisionPermissions.document_upload_size
        ? addedSizeValueOfAllItems < revisionPermissions.document_upload_size
        : true))
  ) {
    const tempId = randomIdGenerate('item-id');
    const payload = {
      id,
      duplicateType: 'item',
      name: `${itemDetails.name} - Copy`,
      tempId,
      nodeId: itemDetails.node_id,
      itemId: itemDetails.id,
      isHomeActivity,
      subType: isHomeActivity ? 'jotsItem' : 'boardsItem',
      itemDetails: itemDetails,
      translation: 'item_name_title',
      description: itemDetails.description,
    };
    duplicateAction(payload);
  }
}

function folderItemsDuplicate(id) {
  const { boardDetails, projectId } = store.getState().BoardGroups,
    itemDetails = boardDetails.items.find((item) => item.id === id),
    tempId = randomIdGenerate('id'),
    duplicateName = getDuplicateNodeName(itemDetails);
  const payload = {
    id,
    name: duplicateName,
    tempId,
    duplicateType: 'folder',
    projectId: projectId,
    nodeId: itemDetails.node_id,
    itemId: id,
    translation: 'item_name_title',
    description: itemDetails.discription,
  };
  duplicateAction(payload);
}

export function documentItemsDuplicate(id, isHomeActivity, nodeId) {
  const { boardDetails, projectId } = store.getState().BoardGroups,
    { jotsProjectItems, jotsProjectDetails } = store.getState().jotsReducer,
    details = isHomeActivity ? jotsProjectItems : boardDetails,
    itemDetails = details.documents
      .find((doc) => doc.node_id === nodeId)
      .items.find((item) => item.id === id),
    tempId = randomIdGenerate('id');
  const nameList = details.documents
    ?.map((values) => values.name?.toLowerCase() || false)
    .filter(Boolean);
  const duplicateName = versionHandling(itemDetails.name, nameList);
  const payload = {
    duplicateType: 'item',
    name: duplicateName,
    tempId,
    nodeId,
    itemId: id,
    projectId: isHomeActivity ? jotsProjectDetails?.id : projectId,
    isHomeActivity: isHomeActivity,
    subType: isHomeActivity ? 'jotsDocumentItem' : 'boardNodeItem',
    translation: 'item_name_title',
    description: itemDetails.description,
  };
  duplicateAction(payload);
}

export function listViewDocumentItemsDuplicate({ id, isHomeActivity, nodeId }) {
  const {
      documentDetails,
      documentProjectId,
      documentNodeId,
    } = store.getState().commonReducer,
    itemDetails = documentDetails.find((item) => item.id === id),
    tempId = randomIdGenerate('id');
  const nameList = documentDetails
    ?.map((values) => values.name?.toLowerCase() || false)
    .filter(Boolean);
  const duplicateName = versionHandling(itemDetails.name, nameList);
  const payload = {
    duplicateType: 'item',
    name: duplicateName,
    tempId,
    nodeId: nodeId || documentNodeId,
    itemId: id,
    projectId: documentProjectId,
    isHomeActivity: isHomeActivity,
    subType: 'listViewFullScreenDocumentItem',
    translation: 'item_name_title',
    description: itemDetails.description,
  };
  duplicateAction(payload);
}

function individualItemsDuplicate(id,isHomeActivity) {
  let itemData={
    itemDetails:'',
    tempId:'',
    nameList:'',
    duplicateName:'',
  }
  let payload;
  if(isHomeActivity){
    const { jotsProjectItems } = store.getState().jotsReducer;
    itemData.itemDetails = jotsProjectItems?.items.find((item)=>item.id === id);
    itemData.nameList = jotsProjectItems?.items?.map((values)=> values?.name?.toLowerCase() || false).filter(Boolean);
    itemData.duplicateName = versionHandling(itemData.itemDetails?.name, itemData.nameList);

    payload = {
      duplicateType: 'item',
      tempId:itemData.tempId,
      nodeId: itemData.itemDetails?.node_id,
      itemId: id,
      projectId: itemData.itemDetails?.project_id,
      isHomeActivity: true,
      translation: 'item_name_title',
      subType: 'jotsItem',
      name: itemData.duplicateName,
      description: itemData.itemDetails?.description,
    };
    duplicateAction(payload);
  }else{
    const { boardDetails, projectId } = store.getState().BoardGroups;
    itemData.itemDetails = boardDetails.items.find((item) => item.id === id);
    itemData.tempId = randomIdGenerate('id');
    itemData.nameList = boardDetails.items
        ?.map((values) => values.name?.toLowerCase() || false)
        .filter(Boolean);
    itemData.duplicateName = versionHandling(itemData.itemDetails.name, itemData.nameList);
    payload = {
      duplicateType: 'item',
      tempId: itemData.tempId,
      nodeId: itemData.itemDetails?.node_id,
      itemId: id,
      projectId: itemData.itemDetails?.project_id || projectId,
      isHomeActivity: false,
      translation: 'item_name_title',
      subType: 'boardItem',
      name: itemData.duplicateName,
      description: itemData.itemDetails?.description,
    }
    duplicateAction(payload);
  }
}
function listViewIndividualItemsDuplicate({ id, itemDetails }) {
  const tempId = randomIdGenerate('id');
  const payload = {
    duplicateType: 'item',
    tempId,
    nodeId: itemDetails?.nodeId,
    itemId: id,
    projectId: itemDetails.projectId,
    isHomeActivity: false,
    translation: 'item_name_title',
    name: `${itemDetails.name} - Copy`,
    description: itemDetails.description,
    subType: itemDetails?.nodeId ? 'listFolderItem' : 'listBoardItem',
  };
  duplicateAction(payload);
}

function jotsIndividualItemsDuplicate(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
) {
  const { jotsProjectItems } = store.getState().jotsReducer,
    itemDetails = jotsProjectItems.items.find((item) => item.id === id),
    tempId = randomIdGenerate('id');
  const payload = {
    itemId: id,
    name: getDuplicateNodeName(itemDetails),
    details: itemDetails,
    tempId,
    projectId: projectId || itemDetails.project_id,
    duplicateType: 'item',
    translation: 'item_name_title',
    isHomeActivity: true,
    subType: 'jotsItem',
    description: itemDetails.description,
  };
  duplicateAction(payload);
}

function groupShare(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
  itemDetails,
) {
  shareObject({ id, location: 'groups', itemDetails, nodeType: 'group' });
}
function listGroupShare({ id, itemDetails }) {
  shareObject({ id, location: 'groups', itemDetails, nodeType: 'group' });
}

function folderShare(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
  itemDetails,
  sharing,
) {
  shareObject({
    id,
    location: 'nodes',
    projectId,
    parentId: projectId,
    itemDetails,
    sharing,
    nodeType: 'folder',
  });
}
function listFolderShare({ id, itemDetails, projectId, data }) {
  shareObject({
    id,
    location: 'nodes',
    parentId: projectId,
    itemDetails,
    sharing: data?.files_count > 0,
    nodeType: 'folder',
  });
}

function documentShare(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  undefinedVariable, // Sujai ->  Can you check and change the variable name
  nodeType,
  data,
  parent,
  itemDetails,
  sharing,
) {
  const { projectId } = store.getState().BoardGroups;
  const { jotsProjectDetails } = store.getState().jotsReducer;
  shareObject({
    id,
    location: 'nodes',
    parentId: isHomeActivity ? jotsProjectDetails?.id : projectId,
    itemDetails,
    sharing,
    nodeType: 'document',
  });
}
function listDocumentShare({
  id,
  isHomeActivity,
  projectId,
  itemDetails,
  data,
}) {
  const { jotsProjectDetails } = store.getState().jotsReducer;
  shareObject({
    id,
    location: 'nodes',
    parentId: isHomeActivity ? jotsProjectDetails?.id : projectId,
    itemDetails,
    sharing: data?.files_count > 0,
    nodeType: 'document',
  });
}

function groupArchive(...args) {
  const id = args[0];
  const data = args[6];
  store.dispatch({
    type: boardGroupActions.ARCHIVE_INDEX_SECTION_CATEGORY,
    payload: data,
  });
  archiveUnArchiveGroupSharedApi(id, true);
}

function listViewGroupArchive({ id, data }) {
  store.dispatch({
    type: boardGroupActions.ARCHIVE_INDEX_SECTION_CATEGORY,
    payload: data,
  });
  archiveUnArchiveGroupSharedApi(id, true);
}
function listViewGroupUnArchive({ id, data }) {
  store.dispatch({
    type: boardGroupActions.UN_ARCHIVE_INDEX_SECTION_CATEGORY,
    payload: data,
  });
  archiveUnArchiveGroupSharedApi(id, false);
}
function groupUnArchive(...args) {
  const data = args[6];
  store.dispatch({
    type: boardGroupActions.UN_ARCHIVE_INDEX_SECTION_CATEGORY,
    payload: data,
  });
  archiveUnArchiveGroupSharedApi(data.id, false);
}
function folderArchive(...args) {
  const id = args[0];
  const data = args[6];
  store.dispatch({
    type: boardGroupActions.UPDATE_ARCHIVE_FOLDER,
    archiveFolderId: id,
  });
  updateAllArchivedList(data, 'folders');
  archiveUnArchiveFolderSharedApi(id);
}
function listViewFolderArchive({ id }) {
  archiveUnArchiveFolderSharedApi(id);
}
function folderUnArchive(...args) {
  const id = args[0];
  const isHomeActivity = args[1];
  const data = args[6];
  const { currentId } = store.getState().BoardGroups;
  const currentDetails = getDetails(id);
  const currentHierarchy = currentDetails?.parentNodeId === currentId;
  store.dispatch({
    type: boardGroupActions.UPDATE_UNARCHIVE_FOLDER,
    unArchiveFolderId: id,
  });
  updateAllArchiveList(id, 'folders', 'node_id');
  addAsyncAction(boardGroupActions.UNARCHIVE_FOLDER, {
    folderId: id,
    projectId: data?.project_id,
    parentId: data?.parent_id,
    currentHierarchy,
    isHomeActivity,
  });
}

function documentArchive(...args) {
  const id = args[0];
  const isHomeActivity = args[1];
  const data = args[6];
  let actions = boardGroupActions,
    actionType = 'ARCHIVE_NODE';
  const { isOpenFullScreenFolderModal } = store.getState().Folder;
  if (isHomeActivity || (!isHomeActivity && !isCloudAreaListView())) {
    store.dispatch({
      type: actions[actionType],
      archivedDocumentNodeId: id,
    });
  }
  if (isOpenFullScreenFolderModal) {
    store.dispatch({
      type: folderActions.OPEN_FULL_SCREEN_FOLDER_MODAL,
      payload: false,
    });
  }
  updateAllArchivedList(data, 'documents');
  archiveUnArchiveFolderSharedApi(id, isHomeActivity, 'document');
}
function documentUnArchive(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
) {
  const { currentId } = store.getState().BoardGroups;
  const { jotsProjectItems } = store.getState().jotsReducer;
  let currentDetails = getDetails(id);
  const currentHierarchy = currentDetails?.parentNodeId === currentId;
  const { isOpenFullScreenFolderModal } = store.getState().Folder;
  if (isHomeActivity) {
    currentDetails = jotsProjectItems.documents.filter(
      (data) => data.node_id === id,
    )[0];
  } else if (currentHierarchy) {
    store.dispatch({
      type: boardGroupActions.UNARCHIVE_DOCUMENT,
      unArchivedDocumentNodeId: id,
    });
  }
  if (isOpenFullScreenFolderModal) {
    store.dispatch({
      type: folderActions.OPEN_FULL_SCREEN_FOLDER_MODAL,
      payload: false,
    });
  }
  updateAllArchiveList(id, 'documents', 'node_id');
  addAsyncAction(boardGroupActions.UNARCHIVE_DOCUMENT_API, {
    folderId: id,
    parentId: data?.parent_id || data?.project_id,
    projectId: data?.project_id || currentDetails.projectId,
    isHomeActivity,
    groupId,
    currentHierarchy,
  });
}

function listViewDocumentUnArchive({
  id,
  currentDetails,
  isHomeActivity,
  groupId,
  currentHierarchy,
  data,
}) {
  updateAllArchiveList(id, 'documents', 'node_id');
  addAsyncAction(boardGroupActions.UNARCHIVE_DOCUMENT_API, {
    folderId: id,
    parentId: data?.parent_id || data?.project_id,
    projectId: data?.project_id || currentDetails.projectId,
    isHomeActivity,
    groupId,
    currentHierarchy,
    isListView: true,
  });
}

function listViewFolderUnArchive({
  id,
  isHomeActivity,
  currentHierarchy,
  data,
}) {
  updateAllArchiveList(id, 'folders', 'node_id');
  addAsyncAction(boardGroupActions.UNARCHIVE_FOLDER, {
    folderId: id,
    projectId: data?.project_id,
    parentId: data?.parent_id,
    currentHierarchy,
    isHomeActivity,
    isListView: true,
  });
}

function groupDelete(id) {
  deleteModalVisibility({ id, deleteItemType: 'group' });
}

function listViewGroupDelete({ id }) {
  deleteModalVisibility({ id, deleteItemType: 'group', isListView: true });
}
function archiveGroupDelete(...args) {
  const id = args[0];
  const parent = args[7] || 'boards';
  deleteModalVisibility({
    id,
    deleteItemType: 'group',
    isArchive: true,
    deleteParent: parent,
    groupId: id,
  });
}

function archiveBoardDelete(...args) {
  const id = args[0];
  const groupId = args[3];
  const parent = args[7] || 'boards';
  deleteModalVisibility({
    id,
    deleteItemType: 'board',
    groupId,
    isArchive: true,
    deleteParent: parent,
    isAllArchiveItems: args[10],
  });
}

function folderDelete(id) {
  deleteModalVisibility({ id, deleteItemType: 'folder' });
}
function folderArchiveDelete(...args) {
  const id = args[0];
  const parent = args[7] || 'boards';
  deleteModalVisibility({ id, deleteItemType: 'folder', isArchive: true, deleteParent: parent, isAllArchiveItems: args[10] });
}
function listViewFolderDelete({ id }) {
  deleteModalVisibility({ id, deleteItemType: 'folder', isListView: true });
}
function listViewDocumentArchiveDelete({ id, projectId }) {
  deleteModalVisibility({
    id,
    deleteItemType: 'document',
    isListView: true,
    projectId,
    isArchive: true,
  });
}

function documentDelete(...args) {
  deleteModalVisibility({
    id: args[0],
    deleteItemType: args[1] ? 'jotsDocument' : 'document',
    isHomeActivity: args[1],
    projectId: args[4],
    isAllArchiveItems: args[10],
  });
}
function documentArchiveDelete(...args) {
  deleteModalVisibility({
    id: args[0],
    deleteItemType: args[1] ? 'jotsDocument' : 'document',
    isHomeActivity: args[1],
    projectId: args[4],
    isArchive: true,
    deleteParent: args[7],
    isAllArchiveItems: args[10],
  });
}
function itemsDelete(id, isHomeActivity) {
  if (isHomeActivity) {
    store.dispatch({
      type: jotsActions.UPDATE_DELETE_JOTS_ITEM_MODAL,
      payload: true,
      deleteId: [id],
    });
  } else {
    store.dispatch({
      type: boardGroupActions.UPDATE_DELETE_ITEM_MODAL,
      payload: true,
      deleteId: [id],
    });
  }
}
function individualItemDelete(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
) {
  deleteModalVisibility({
    id,
    projectId,
    nodeId,
    deleteParent: parent,
  });
}

function documentItemDelete(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
) {
  deleteModalVisibility({
    id,
    projectId,
    nodeId,
    deleteParent: parent,
    isHomeActivity,
    nodeType: parent === 'jots_document' ? 'jotsDocumentItem' : 'documentItem',
  });
}

function capturesItemDelete(id) {
  store.dispatch({
    type: capturesActions.UPDATE_DELETE_CAPTURE_MODAL,
    payload: true,
    deleteId: [id],
  });
}
function copySparkLink(id, isHomeActivity, nodeId, groupId, projectId, nodeType, data, parent, itemDetails, sharing, isAllItems) {
  const url = id;
  const sharingData = {
    id: nodeId || projectId || groupId,
    itemType: nodeType,
  };
  copyToClipboard({
    str: url,
    bannerText: Translate({ children: 'link_copied_ready_for_sharing' }),
    sharingData,
    isFromSparkContextMenu: true,
  });
}
export function listCopySparkLink({ url, item }) {
  function getType() {
    if (item?.object_type === 'page') {
      return 'documents';
    } else if (item?.object_type === 'folder') {
      return 'folders';
    }
    return 'boards';
  }
  const sharingData = {
    ...item,
    id: item?.node_id || item?.project_id || item?.group_id,
    itemType: getType(),
  };
  copyToClipboard({
    str: item?.url || ' ',
    bannerText: Translate({ children: 'link_copied_ready_for_sharing' }),
    sharingData,
    preventShareRedirection: true,
  });
}
function pauseSharingSpark(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
) {
  store.dispatch({
    type: sharedSparkActions.PAUSE_RESUME_SHARING,
    id: data.id,
    alert: () => alertView({
      alertText: 'menu_pause_message',
      type: 'success',
      delay: 3,
    }),
  });
}
function listPauseSharingSpark({
  nodeId,
  groupId,
  projectId,
  groupIds,
  sparkNodeType,
}) {
  updateItemIconLoader(nodeId || projectId || groupId || groupIds, true);
  store.dispatch({
    type: sharedSparkActions.PAUSE_LATEST_SHARED_SPARKS,
    payload: {
      nodeId: nodeId,
      groupId: groupId || groupIds,
      projectId: projectId,
      nodeType: sparkNodeType,
      pause: true,
      isListView: true,
    },
  });
}
function resumeSharingSpark(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
) {
  store.dispatch({
    type: sharedSparkActions.PAUSE_RESUME_SHARING,
    id: data.id,
    alert: () => alertView({
      alertText: 'menu_resume_message',
      type: 'success',
      delay: 3,
    }),
  });
}
function listResumeSharingSpark({
  nodeId,
  groupId,
  projectId,
  groupIds,
  sparkNodeType,
}) {
  updateItemIconLoader(nodeId || projectId || groupId || groupIds, true);
  store.dispatch({
    type: sharedSparkActions.RESUME_LATEST_SHARED_SPARKS,
    payload: {
      nodeId: nodeId,
      groupId: groupIds,
      projectId: projectId,
      nodeType: sparkNodeType,
      pause: false,
    },
  });
}
function sparkDelete(id, isHomeActivity, nodeId, groupId, projectId, nodeType, data) {
  deleteModalVisibility({
    projectId,
    nodeId,
    groupId,
    deleteItemType: 'shared_spark',
    nodeType,
    id: data.id
  });
}
function listSparkDelete({ nodeId, groupId, projectId, sparkNodeType }) {
  deleteModalVisibility({
    projectId,
    nodeId,
    groupId,
    deleteItemType: 'shared_spark',
    nodeType: sparkNodeType,
    isListView: true,
  });
}
function showSourceHelper({ data }) {
  updateHistory('/app');
  const payload = {
    visibility: false,
  };
  store.dispatch({
    type: sharedSparkActions.SHARE_MODAL,
    payload,
  });
  if (data?.object_type === 'folder') {
    localStorage.setItem('activeTab', ['activities']);
  } else {
    localStorage.setItem('activeTab', ['home', 'activities']);
  }
  store.dispatch({
    type: commonActions.SET_REDIRECTED_ITEM_DETAILS,
    payload: {
      redirectedItemDetails: {
        id: data?.id,
        parentId: data?.parent_id,
        objectType: data?.object_type,
      },
    },
  });
  store.dispatch({
    type: commonActions.HIGHLIGHT_ITEM,
    payload: data?.id,
  });
}
export function showSourceSpark(...args) {
  showSourceHelper({ data: args[6] });
}
function listShowSourceSpark({ data }) {
  showSourceHelper({ data });
}
function listViewIndividualItemDelete({ id, itemDetails, isHomeActivity }) {
  deleteModalVisibility({
    id,
    deleteItemType: isHomeActivity ? 'jotsListItem' : 'filesListItem',
    projectId: itemDetails.projectId,
    nodeId: itemDetails?.nodeId,
  });
}
function listViewDocumentItemsDelete({
  id,
  isHomeActivity,
  parentId,
  itemDetails,
  parent,
  nodeId,
  projectId,
}) {
  const { documentProjectId, documentNodeId } = store.getState().commonReducer;
  deleteModalVisibilityModal({
    id,
    projectId: projectId || documentProjectId,
    nodeId: nodeId || documentNodeId,
    deleteParent: parent,
    isHomeActivity,
    nodeType: 'listViewFullScreenDocumentItem',
  });
}

export function contextOnclick(
  context,
  type,
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
  itemDetails,
  sharing,
  isAllItems,
) {
  const action = functionMapping[context]?.[type];
  action(
    id,
    isHomeActivity,
    nodeId,
    groupId,
    projectId,
    nodeType,
    data,
    parent,
    itemDetails,
    sharing,
    isAllItems,
  );
}
export function listContextOnClick(data = {}) {
  const { context, type } = data;
  const action = listViewFunctionMapping[context]?.[type];
  action(data);
}

function archiveUnArchiveGroupSharedApi(id, archiveAction) {
  addAsyncAction(boardGroupActions.ARCHIVE_CATEGORY, {
    groupId: id,
    payload: {
      type: 'category',
      archived: archiveAction,
    },
  });
}
export function archiveUnArchiveFolderSharedApi(id, isHomeActivity, type) {
  addAsyncAction(boardGroupActions.ARCHIVE_FOLDER, {
    payload: {
      type: type === 'document' ? 'page' : 'folder',
      archived: true,
    },
    folderId: id,
    isHomeActivity,
    type,
  });
}
export function deleteGroupSharedApi({ id }) {
  deleteAsyncLoaderVisibility(true);
  updateIndexSection({ deletedId: id });
  store.dispatch({
    type: boardGroupActions.DELETE_CATEGORY,
    payload: {
      is_category: true,
      object_ids: [id],
    },
    id,
    callback: () => {
      deleteAsyncLoaderVisibility(false);
      myFilesAlertView({
        alertText: 'delete_board_group_success',
        type: 'success',
        delay: 4,
      });
    },
  });
}

export function deleteBoardSharedApi({
  id,
  isListView = false,
  isArchive = false,
  isAllArchiveItems = false,
  isHomeActivity = false,
}) {
}

export function deleteFolderSharedApi({
  id,
  isListView = false,
  projectId,
  }){
  addAsyncAction(boardGroupActions.DELETE_FOLDER, {
    folderId: id,
    projectId,
    updateList: isListView,
  });
}

export function deleteDocumentSharedApi({
  id,
  projectId,
  isHomeActivity = false,
  nodeType,
  alertMessage,
}) {
  addAsyncAction(folderActions.ASYNC_DELETE_NODE, {
    nodeId: id,
    projectId,
    isHomeActivity,
    nodeType,
    alertMessage,
  });
}

export function determineJotsReducerActionForViewMode(action) {
  const { jotsDocumentView } = store.getState().jotsReducer;
  if (jotsDocumentView === 'list') {
    return `LIST_VIEW_${action}`;
  }
  return action;
}

export function duplicateAction(payload) {
  store.dispatch({
    type: commonActions.DUPLICATE_MODAL,
    payload: {
      ...payload,
      visibility: true,
    },
  });
}

export function extractProjects(isHomeActivity) {
  const { allGroupList } = store.getState().BoardGroups;
  const projectsArray = [];
  if (isHomeActivity) {
    const { jotsProjectDetails } = store.getState().jotsReducer;
    projectsArray.push({
      value: jotsProjectDetails?.id,
      label: jotsProjectDetails.name,
    });
  } else {
    allGroupList.forEach((group) => {
      group.folders.forEach((project) => {
        projectsArray.push({
          value: project.project_id,
          label: project.name,
        });
      });
    });
  }
  return projectsArray;
}

export function extractGroups() {
  const { allGroupList } = store.getState().BoardGroups;
  const groupsArray = [];
  allGroupList.forEach((group) => {
    groupsArray.push({
      value: group.group_id,
      label: group.name,
    });
  });
  return groupsArray;
}

function itemShare(id, isHomeActivity, nodeId) {
  const { jotsProjectItems } = store.getState().jotsReducer;
  const { boardDetails } = store.getState().BoardGroups;
  const { captureLists } = store.getState().Capture;
  const selectedCapture = captureLists.filter((value) => value.id === id);
  let details, getItemDetails;
  // For capture item identification, we need to improve the logic
  if (selectedCapture.length) {
    getItemDetails = selectedCapture[0];
  } else {
    // If nodeId present, It was a document item.
    details = isHomeActivity ? jotsProjectItems : boardDetails;
    if (nodeId) {
      const selectedDocument =
        details.documents.find((item) => item.node_id === nodeId) || details;
      getItemDetails = selectedDocument.items.find(
        (item) => item.id === id,
      );
    } else {
      getItemDetails = details?.items?.find((item) => item.id === id);
    }
  }
  let shareData;
  if (getItemDetails?.type === 'location') {
    shareData = `https://www.google.com/maps/search/?api=1&query=${getItemDetails?.content?.latitude},${getItemDetails?.content?.longitude}`;
  } else if (getItemDetails?.type === 'url') {
    shareData = getItemDetails?.content?.url;
  } else {
    store.dispatch({
      type: commonActions.FETCH_SHARE_URL,
      itemId: getItemDetails?.id,
    });
  }
  const socialMediaModal = {
    visibility: true,
    shareData,
    isShareIndividualItem: true,
  };
  store.dispatch({
    type: commonActions.SOCIAL_MEDIA_MODAL,
    socialMediaModal,
  });
}

function listItemShare({ id, data }) {
  const { documentDetails } = store.getState().commonReducer;
  const selectedCapture = documentDetails.filter(
    (value) => value.id === id,
  );
  let getItemDetails;
  // For capture item identification, we need to improve the logic
  if (selectedCapture.length && !data) {
    getItemDetails = selectedCapture[0];
  } else {
    getItemDetails = data;
  }
  let shareData;
  if (getItemDetails.type === 'note') {
    const div = document.createElement('div');
    div.innerHTML = getItemDetails.content.note;
    shareData = div.textContent;
  } else if (getItemDetails.type === 'location') {
    shareData = `https://www.google.com/maps/search/?api=1&query=${getItemDetails.content.latitude},${getItemDetails.content.longitude}`;
  } else if (getItemDetails.type === 'url') {
    shareData = getItemDetails.content.url;
  } else if (getItemDetails.type === 'contact') {
    let contactDetail;
    getItemDetails = getItemDetails.content;
    if (getItemDetails.first_name?.status || getItemDetails.last_name?.status) {
      contactDetail = `Name: ${getItemDetails.first_name.value || ''} ${
        getItemDetails.last_name.value || ''
      }\n`;
    }
    if (getItemDetails.nick_name?.status) {
      contactDetail = `${contactDetail} Nick name: ${getItemDetails.nick_name.value}\n`;
    }
    if (getItemDetails.personal?.email?.status) {
      contactDetail = `${contactDetail} Home email: ${
        getItemDetails.personal.email.value || ''
      }\n`;
    }
    if (getItemDetails.personal?.facebook?.status) {
      contactDetail = `${contactDetail} FaceBook: ${
        getItemDetails.personal.facebook.value || ''
      }\n`;
    }
    if (getItemDetails.personal?.address?.status) {
      contactDetail = `${contactDetail} Home address: ${
        getItemDetails.personal.address.value || ''
      }\n`;
    }
    if (getItemDetails.personal?.phone_number?.status) {
      contactDetail = `${contactDetail} Home phone: ${
        getItemDetails.personal.phone_number.value || ''
      }\n`;
    }
    if (getItemDetails.personal?.mobile_number?.status) {
      contactDetail = `${contactDetail} Home mobile: ${
        getItemDetails.personal.mobile_number.value || ''
      }\n`;
    }
    if (getItemDetails.work?.organization?.status) {
      contactDetail = `${contactDetail} Organization: ${
        getItemDetails.work.organization.value || ''
      }\n`;
    }
    if (getItemDetails.work?.email?.status) {
      contactDetail = `${contactDetail} Work email: ${
        getItemDetails.work.email.value || ''
      }\n`;
    }
    if (getItemDetails.work?.mobile_number?.status) {
      contactDetail = `${contactDetail} Work mobile: ${
        getItemDetails.work.mobile_number.value || ''
      }\n`;
    }
    if (getItemDetails.work?.address?.status) {
      contactDetail = `${contactDetail} Work address: ${
        getItemDetails.work.address.value || ''
      }\n`;
    }
    shareData = contactDetail;
  } else {
    store.dispatch({
      type: commonActions.FETCH_SHARE_URL,
      itemId: getItemDetails.id,
    });
  }
  const socialMediaModal = {
    visibility: true,
    shareData,
    isShareIndividualItem: true,
  };
  store.dispatch({
    type: commonActions.SOCIAL_MEDIA_MODAL,
    socialMediaModal,
  });
}

function capturesItemDownload(id) {
  const { captureLists } = store.getState().Capture;
  const selectedCapture = captureLists.filter(
    (value) => value.id === id,
  )[0];
  let itemsForDownload =
    !['url', 'note', 'location', 'contact'].includes(selectedCapture.type) &&
    selectedCapture?.content?.url;
  const downloadCaptureList = {
    items: [selectedCapture.id],
    downloadItemsList: [selectedCapture],
  };
  if (itemsForDownload) {
    store.dispatch({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'downloadModal',
      payload: {
        visibility: true,
        isFromCapture: true,
      },
    });
    store.dispatch({
      type: commonActions.DOWNLOAD_PROGRESS_STATUS,
      payload: {
        totalItemCount: '1',
      }
    });
  }
  setTimeout(() => {
    downloadItems(downloadCaptureList, 'captures');
  }, 1000)
}
function downloadItem(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
) {
  const itemsForDownload =
    !['url', 'note', 'location', 'contact'].includes(data?.type) &&
    data?.content?.url;
  const downloadCaptureList = {
    items: [id],
    downloadItemsList: [data],
  };
  if (itemsForDownload) {
    store.dispatch({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'downloadModal',
      payload: {
        visibility: true,
        isFromCapture: true,
      },
    });
    store.dispatch({
      type: commonActions.DOWNLOAD_PROGRESS_STATUS,
      payload: {
        totalItemCount: '1',
      }
    });
  }
  setTimeout(() => {
    downloadItems(downloadCaptureList, isHomeActivity ? 'jots' : 'boards');
  }, 1000)
}

function listDownloadItem({ id, data, isHomeActivity }) {
  const itemsForDownload =
    !['url', 'note', 'location', 'contact'].includes(data?.type) &&
    data?.content?.url;
  const downloadCaptureList = {
    items: [id],
    downloadItemsList: [data],
  };
  if (itemsForDownload) {
    store.dispatch({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'downloadModal',
      payload: {
        visibility: true,
        isFromCapture: true,
      },
    });
    store.dispatch({
      type: commonActions.DOWNLOAD_PROGRESS_STATUS,
      payload: {
        totalItemCount: '1',
      }
    });
  }
  setTimeout(() => {
    downloadItems(downloadCaptureList, isHomeActivity ? 'jots' : 'boards');
  }, 1000)
}
export function shareObject({
  id,
  location,
  parentId,
  url,
  itemDetails,
  nodeType,
}) {
  const payload = {
    visibility: true,
    location,
    id,
    parentId,
    url,
    itemDetails,
    nodeType,
  };
  store.dispatch({
    type: sharedSparkActions.SHARE_MODAL,
    payload,
  });
  // TODO: Handle this action after client's confirmation
  // store.dispatch({
  //   type: actions.EMPTY_SHARE_ALERT_VISIBILITY,
  //   payload: true,
  // });
}

export function updateItemIconLoader(id, value) {
  store.dispatch({
    type: sharedSparkActions.SHARED_SPARK_ICONS_LOADER,
    payload: {
      id: id,
      iconLoader: value,
    },
  });
}

function cutCaptureItem(...args) {
  const data = args[6];

  // Cut the Item
  store.dispatch({
    type: commonActions.CUT_ITEM,
    payload: {
      itemCutted: [data],
      isCaptureItemCut: true,
    },
  });

  // Remove Item from Capture
  removeItemsFromCaptureHelper({
    activeId: data.id,
    isMultipleCaptureSelect: false,
  });
}
function replaceImage(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
) {
  renderAddBar({
    title: data?.name,
    addBarType: data?.type === 'image' ? 'unknown' : data?.type,
    nodeId: nodeId,
    parent: 'jots_document',
    projectId,
    addBarTitle: data?.name,
    isEdit: true,
    data,
  });
}
function jotsIndividualItemProperties(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
) {
  store.dispatch({
    type: capturesActions.SHOW_CAPTURE_DESCRIPTION_MODAL,
    payload: data,
    visibility: true,
  });
  store.dispatch({
    type: capturesActions.ITEM_DETAILS,
    itemParent: parent,
    itemProjectId: projectId,
  });
}

export function closePropertyModal() {
  store.dispatch({
    type: capturesActions.SHOW_CAPTURE_DESCRIPTION_MODAL,
    visibility: false,
  });
  store.dispatch({
    type: capturesActions.ITEM_DETAILS,
  });
  setPropertiesLoader({ visibility: false });
}

export function documentItemProperties(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
) {
  store.dispatch({
    type: capturesActions.SHOW_CAPTURE_DESCRIPTION_MODAL,
    payload: data,
    visibility: true,
  });
  store.dispatch({
    type: capturesActions.ITEM_DETAILS,
    itemParent: parent,
    itemProjectId: projectId,
  });
}
function individualItemProperties(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
) {
  store.dispatch({
    type: capturesActions.SHOW_CAPTURE_DESCRIPTION_MODAL,
    payload: data,
    visibility: true,
  });
  store.dispatch({
    type: capturesActions.ITEM_DETAILS,
    itemParent: parent,
    itemProjectId: projectId,
  });
}

function listDocumentItemProperties({
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
}) {
  store.dispatch({
    type: capturesActions.SHOW_CAPTURE_DESCRIPTION_MODAL,
    payload: data,
    visibility: true,
  });
  store.dispatch({
    type: capturesActions.ITEM_DETAILS,
    itemParent: parent,
    itemProjectId: projectId,
  });
}
function listIndividualItemProperties({
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
}) {
  store.dispatch({
    type: capturesActions.SHOW_CAPTURE_DESCRIPTION_MODAL,
    payload: data,
    visibility: true,
  });
  store.dispatch({
    type: capturesActions.ITEM_DETAILS,
    itemParent: parent,
    itemProjectId: projectId,
  });
}
function editItem(
  id,
  isHomeActivity,
  nodeId,
  groupId,
  projectId,
  nodeType,
  data,
  parent,
) {
  renderAddBar({
    data,
    isDocumentItem: false,
    isHomeActivity,
    title: data?.name,
    addBarType: data?.type,
    isEdit: true,
    parent,
    addBarTitle: data?.name,
  });
  store.dispatch({
    type: commonActions.SET_NOTE_CREATE_SAVE_STATE,
    payload: {
      isNoteCreateSave: false,
      isNoteCreateApiInitiated: true,
    },
  });
  store.dispatch({
    type: commonActions.SET_IS_EDIT_NOTE_STATE,
    payload: true,
  });
}
function listEditItem({ isHomeActivity, data, parent, projectId }) {
  renderAddBar({
    data,
    isDocumentItem: false,
    isHomeActivity,
    title: data?.name,
    addBarType: data?.type,
    isEdit: true,
    parent,
    addBarTitle: data?.name,
    itemProjectId: projectId,
    updateList: true,
    listViewIds: { projectId, nodeId: data?.node_id },
  });
  store.dispatch({
    type: commonActions.SET_NOTE_CREATE_SAVE_STATE,
    payload: {
      isNoteCreateSave: false,
      isNoteCreateApiInitiated: true,
    },
  });
  store.dispatch({
    type: commonActions.SET_IS_EDIT_NOTE_STATE,
    payload: true,
  });
}

export function updateCaptureDescription({
  value,
  captureDescriptionModalData,
}) {
  const {
    itemParent: parent,
    itemProjectId: projectId,
  } = store.getState().Capture;
  let tempData = cloneDeep(captureDescriptionModalData);
  tempData.description = value.description;
  tempData.name = value.name;
  tempData.content = {
    ...captureDescriptionModalData.content,
    caption: value.caption,
  };
  tempData.updated_at = getTimezoneFormat();
  tempData.last_sync_at = getTimezoneFormat();
  const payload = {
    payload: {
      name: value?.name,
      description: value?.description,
      caption: value?.caption,
      type: captureDescriptionModalData.type,
    },
    projectId: projectId || tempData?.project_id,
    nodeId: tempData?.node_id,
    itemId: tempData?.id,
  };
  setPropertiesLoader({ visibility: true });
  if (parent === 'jots') {
    editIndividualBoardItem({ tempData, payload }, parent === 'jots', false);
  } else if (parent === 'jots_document') {
    editJotsDocumentItem(
      {
        tempData,
      },
      false,
    );
  } else if (parent === 'files_document') {
    editDocumentItem({ tempData }, false);
  } else if (parent === 'folder') {
    editFolderItem({ tempData }, false);
  } else {
    store.dispatch({
      type: capturesActions.UPDATE_CAPTURE_ITEM_SUCCESS,
      payload: tempData,
    });
  }
  addAsyncAction(addbarActions.UPDATE_ITEM, {
    id: captureDescriptionModalData.id,
    payload: {
      name: value.name,
      description: value.description,
      caption: value.caption,
      type: captureDescriptionModalData.type,
      parent_id: captureDescriptionModalData.parent_id,
    },
    isFromUpdateCaptureDescription: true,
  });
}

function updateAllArchiveList(id, itemType, key) {
  const { allArchivedList } = store.getState().commonReducer;
  if (!isObjectEmpty(allArchivedList)) {
    store.dispatch({
      type: commonActions.UPDATE_ALL_ARCHIVED_LIST,
      payload: id,
      itemType,
      key,
    });
  }
}

export function updateAllArchivedList(data, itemType) {
  const { allArchivedList } = store.getState().commonReducer;
  if (!isObjectEmpty(allArchivedList)) {
    store.dispatch({
      type: commonActions.UPDATE_ALL_ARCHIVED_LIST_ITEM,
      data,
      itemType,
    });
  }
}
