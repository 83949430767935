import React from 'react';
import { Translate } from 'utility/helpers';
import { ReactComponent as EditIcon } from 'images/svgs/contextMenu/IconEdit.svg';
import { ReactComponent as DuplicateIcon } from 'images/svgs/contextMenu/IconDuplicate.svg';
import { ReactComponent as PasteIcon } from 'images/svgs/contextMenu/IconPaste.svg';
import { ReactComponent as CutIcon } from 'images/svgs/contextMenu/IconCut.svg';
import { ReactComponent as ShareIcon } from 'images/svgs/contextMenu/IconShare.svg';
import { ReactComponent as ArchiveIcon } from 'images/svgs/contextMenu/IconArchive.svg';
import { ReactComponent as DeleteIcon } from 'images/svgs/contextMenu/IconDelete.svg';
import { ReactComponent as Url } from 'images/svgs/url.svg';
import { ReactComponent as PauseSharingIcon } from 'images/svgs/hand.svg';
import { ReactComponent as FolderIconUsage } from 'images/svgs/latest-V2/folderIconUsage.svg';
import { ReactComponent as DownloadIcon } from 'images/svgs/downloadSecondaryIcon.svg';
import { ReactComponent as IconUnArchive } from 'images/svgs/contextMenu/IconUnArchive.svg';
import { ReactComponent as IconPublish } from 'images/svgs/contextMenu/IconPublish.svg';
import { ReactComponent as IconCopyLink } from 'images/svgs/contextMenu/IconCopyLink.svg';
import SyncIcon from 'components/shared/SyncIcon';
// import { ReactComponent as ReplaceIcon } from 'images/svgs/contextMenu/replaceIcon.svg';
import { ReactComponent as InfoIcon } from 'images/svgs/infoNewIcon.svg';

export const groupContext = [
  {
    key: 'edit_properties',
    label: <Translate>edit_group_properties</Translate>,
    icon: <EditIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  // {
  //   key: 'share',
  //   label: <Translate>share_group</Translate>,
  //   icon: <ShareIcon />,
  // },
  {
    key: 'archive',
    label: <Translate>archive</Translate>,
    icon: <ArchiveIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete_group</Translate>,
    icon: <DeleteIcon />,
  },
];

export const folderContext = [
  {
    key: 'publish',
    label: <Translate>publish</Translate>,
    icon: <IconPublish />,
  },
  {
    key: 'update_latest_share',
    label: <Translate>update_latest_share</Translate>,
    icon: <SyncIcon />,
  },
  {
    key: 'copy_link',
    label: <Translate>copy_link</Translate>,
    icon: <IconCopyLink />,
  },
  {
    key: 'share',
    label: <Translate>share_folder</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'edit_properties',
    label: <Translate>edit_folder_properties</Translate>,
    icon: <EditIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'archive',
    label: <Translate>archive</Translate>,
    icon: <ArchiveIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete_folder</Translate>,
    icon: <DeleteIcon />,
  },
];
export const documentContext = [
  {
    key: 'edit_properties',
    label: <Translate>edit_properties</Translate>,
    icon: <EditIcon />,
  },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'paste_content_here',
    label: <Translate>paste_content_here</Translate>,
    icon: <PasteIcon />,
  },
  {
    key: 'archive',
    label: <Translate>archive</Translate>,
    icon: <ArchiveIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete_document</Translate>,
    icon: <DeleteIcon />,
  },
];
export const tourDocumentContext = [
  {
    key: 'edit_properties',
    label: <Translate>edit_properties</Translate>,
    icon: <EditIcon />,
    disabled: true,
  },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
    disabled: true,
  },
  {
    key: 'archive',
    label: <Translate>archive</Translate>,
    icon: <ArchiveIcon />,
    disabled: true,
  },
  {
    key: 'delete',
    label: <Translate>delete_document</Translate>,
    icon: <DeleteIcon />,
    disabled: true,
  },
];
export const itemsContext = [
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete_item</Translate>,
    icon: <DeleteIcon />,
  },
];

export const groupArchivedContext = [
  {
    key: 'unarchive',
    label: <Translate>unarchive</Translate>,
    icon: <ArchiveIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete_group</Translate>,
    icon: <DeleteIcon />,
  },
];
export const boardArchivedContext = [
  {
    key: 'unarchive',
    label: <Translate>unarchive</Translate>,
    icon: <ArchiveIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete_board</Translate>,
    icon: <DeleteIcon />,
  },
];
export const folderArchivedContext = [
  {
    key: 'unarchive',
    label: <Translate>unarchive</Translate>,
    icon: <ArchiveIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete_folder</Translate>,
    icon: <DeleteIcon />,
  },
];
export const documentArchivedContext = [
  {
    key: 'unarchive',
    label: <Translate>unarchive</Translate>,
    icon: <IconUnArchive />,
  },
  {
    key: 'delete',
    label: <Translate>delete_document</Translate>,
    icon: <DeleteIcon />,
  },
];

export const categoryArchivedContext = [
  {
    key: 'unarchive',
    label: <Translate>unarchive</Translate>,
    icon: <IconUnArchive />,
  },
  {
    key: 'delete',
    label: <Translate>delete_document</Translate>,
    icon: <DeleteIcon />,
  },
];

export const commonItemsContext = [
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const documentItemsContext = [
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const captureItemsContextWithoutDownLoad = [
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const captureItemsContext = [
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  {
    key: 'download',
    label: <Translate>download</Translate>,
    icon: <DownloadIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const sparkContext = [
  {
    key: 'copy_link',
    label: <Translate>copy_link</Translate>,
    icon: <Url width={18} />,
  },
  {
    key: 'pause_sharing',
    label: <Translate>pause_sharing</Translate>,
    icon: <PauseSharingIcon width={18} />,
  },
  {
    key: 'show_source',
    label: <Translate>show_source</Translate>,
    icon: <FolderIconUsage width={18} />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon width={18} />,
  },
];

export const sparkContextfree = [
  {
    key: 'copy_link',
    label: <Translate>copy_link</Translate>,
    icon: <Url width={18} />,
  },
  {
    key: 'show_source',
    label: <Translate>show_source</Translate>,
    icon: <FolderIconUsage width={18} />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon width={18} />,
  },
];

export const sparkResumeContext = [
  {
    key: 'copy_link',
    label: <Translate>copy_link</Translate>,
    icon: <Url width={18} />,
  },
  {
    key: 'resume_sharing',
    label: <Translate>resume_sharing</Translate>,
    icon: <PauseSharingIcon width={18} />,
  },
  {
    key: 'show_source',
    label: <Translate>show_source</Translate>,
    icon: <FolderIconUsage width={18} />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon width={18} />,
  },
];
export const imageItemsContext = [
  {
    key: 'properties',
    label: <Translate>image_properties</Translate>,
    icon: <InfoIcon />,
  },
  {
    key: 'download',
    label: <Translate>download</Translate>,
    icon: <DownloadIcon />,
  },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  // {
  //   key: 'replace',
  //   label: <Translate>replace_image</Translate>,
  //   icon: <ReplaceIcon />,
  // },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const videoItemsContext = [
  {
    key: 'properties',
    label: <Translate>video_properties</Translate>,
    icon: <InfoIcon />,
  },
  {
    key: 'download',
    label: <Translate>download</Translate>,
    icon: <DownloadIcon />,
  },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  // {
  //   key: 'replace',
  //   label: <Translate>replace_video</Translate>,
  //   icon: <ReplaceIcon />,
  // },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const noteItemsContext = [
  {
    key: 'properties',
    label: <Translate>note_properties</Translate>,
    icon: <InfoIcon />,
  },
  // TODO: Card-2148 CLick on the contact opens edit modal, so having same in context menu duplicating the functionality
  // {
  //   key: 'edit_note',
  //   label: <Translate>edit_note</Translate>,
  //   icon: <EditIcon />,
  // },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];
export const listItemsContext = [
  {
    key: 'properties',
    label: <Translate>list_properties</Translate>,
    icon: <InfoIcon />,
  },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const unknownItemsContext = [
  {
    key: 'download',
    label: <Translate>download</Translate>,
    icon: <DownloadIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const locationItemsContext = [
  {
    key: 'properties',
    label: <Translate>location_properties</Translate>,
    icon: <InfoIcon />,
  },
  // TODO: Card-2148 CLick on the location opens edit modal, so having same in context menu duplicating the functionality
  // {
  //   key: 'update_location',
  //   label: <Translate>update_location</Translate>,
  //   icon: <SyncIcon />,
  // },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const audioItemsContext = [
  {
    key: 'properties',
    label: <Translate>sound_properties</Translate>,
    icon: <InfoIcon />,
  },
  {
    key: 'download',
    label: <Translate>download</Translate>,
    icon: <DownloadIcon />,
  },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  // {
  //   key: 'replace',
  //   label: <Translate>replace_sound</Translate>,
  //   icon: <ReplaceIcon />,
  // },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const contactItemsContext = [
  // TODO: Card-2148 CLick on the contact opens edit modal, so having same in context menu duplicating the functionality
  // {
  //   key: 'edit_contact',
  //   label: <Translate>edit_contact</Translate>,
  //   icon: <EditIcon />,
  // },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const urlItemsContext = [
  {
    key: 'properties',
    label: <Translate>link_properties</Translate>,
    icon: <InfoIcon />,
  },
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
  },
  {
    key: 'cut_item',
    label: <Translate>cut_content</Translate>,
    icon: <CutIcon />,
  },
  {
    key: 'duplicate',
    label: <Translate>duplicate</Translate>,
    icon: <DuplicateIcon />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon />,
  },
];

export const sparkSavedContext = [
  {
    key: 'share',
    label: <Translate>share</Translate>,
    icon: <ShareIcon />,
    children: [
      {
        label: 'as Webpage',
        key: 'share_web_page',
      },
      {
        label: 'as PDF',
        key: 'share_pdf',
      }
    ]
  },
  {
    key: 'show_source',
    label: <Translate>show_source</Translate>,
    icon: <FolderIconUsage width={18} />,
  },
  {
    key: 'delete',
    label: <Translate>delete</Translate>,
    icon: <DeleteIcon width={18} />,
  },
];
