import sharedSparkActions from '../../redux/sharedSparks/actions';
import { store } from '../../redux/store';
import Compressor from 'compressorjs';
import Resizer from 'react-image-file-resizer';
import { handleException, uploadMedia } from '../../utility/helpers';
import commonActions from '../../redux/common/actions';

export function closeSparkModal() {
  store.dispatch({
    type: sharedSparkActions.RESET_SPARK_MODAL,
  });
}

export function uploadMediaItems(data = {}) {
  const {
    uploadData,
    successCallback,
    quality = 0.8,
    minWidth = 200,
    minHeight = 200,
    maxWidth = 200,
    maxHeight = 200,
  } = data;
  const mimeTypes = ['image/bmp', 'image/jpg', 'image/jpeg'];
  const defaultMimeType = 'image/jpeg';
  const mimeType = mimeTypes.includes(uploadData?.type)
    ? defaultMimeType
    : uploadData?.type;
  new Compressor(uploadData, {
    quality,
    mimeType,
    async success(compressedFile) {
      Resizer.imageFileResizer(
        compressedFile,
        maxWidth,
        maxHeight,
        compressedFile.type.split('image/')[1],
        100,
        0,
        (outputFile) => {
          uploadMedia(
            {
              file: outputFile,
              name: `SP_${Date.now()}-${outputFile.name}`.replace(/ /gi, '-'),
            },
            (data) => {
              successCallback(data?.key?.split('/')[1].replace(/ /gi, '-'));
            },
            (err) => {
              console.error(err);
            },
          );
        },
        'file',
        minWidth,
        minHeight,
      );
    },
    error(err) {
      console.error(err.message);
    },
  });
}

export async function shareSpark(data = {}) {
  const { shareAs, sparkConfig, shareId, url, nodeType, status } = data;
  const socialMediaModal = {
    visibility: true,
    loader: true,
    nodeType,
  };
  let sparkAvatar, sparkHeaderImage;
  if(status !== 'save') {
    store.dispatch({
      type: commonActions.SOCIAL_MEDIA_MODAL,
      socialMediaModal,
    });
  }
  const uploadPromise = (config, isAvatar = false) =>
    new Promise((resolve, reject) => {
      uploadMediaItems({
        uploadData: config,
        successCallback: (data) => {
          if (data) {
            resolve(data);
            if (isAvatar) {
              sparkAvatar = data;
            } else {
              sparkHeaderImage = data;
            }
          } else {
            reject();
          }
        },
      });
    });

  // noinspection ES6MissingAwait
  const avatarPromise = sparkConfig?.spark_profile_image
    ? uploadPromise(sparkConfig.spark_profile_image, true)
    : null;
  // noinspection ES6MissingAwait
  const headerImagePromise = sparkConfig?.spark_image
    ? uploadPromise(sparkConfig.spark_image)
    : null;

  const promises = [avatarPromise, headerImagePromise].filter(Boolean);
  try {
    await Promise.all(promises);
    const payload = {
      share_as: shareAs,
      style: sparkConfig?.style || 'simple',
      name: sparkConfig?.title,
      description: sparkConfig?.description,
      user_first_name: sparkConfig?.user_first_name,
      user_last_name: sparkConfig?.user_last_name,
      user_avatar: sparkAvatar,
      header_image: sparkHeaderImage,
      status: status || 'share'
    };
    if (!url) {
      if (!sparkConfig?.apply_template) {
        delete payload.style;
      }
    }
    store.dispatch({
      type: sharedSparkActions.SHARE_OBJECT,
      payload,
      shareId
    });
  } catch (error) {
    handleException(error);
  }
}
