export default {
  HOST_URL: process.env.REACT_APP_API_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  PLANS_PAGE_URL:
    process.env.REACT_APP_PLANS_PAGE_URL || 'https://plans.organyz.com',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'content-language': 'en',
  },
  INTERACTIVE_ONBOARDING_ASSET_URL:
    process.env.REACT_APP_INTERACTIVE_ONBOARDING_ASSET_URL,
  BUCKET_NAME: process.env.REACT_APP_BUCKET_NAME,
  BUCKET_DIRECTORY: process.env.REACT_APP_BUCKET_DIRECTORY,
  PATHFIX_KEY: process.env.REACT_APP_PATHFIX_KEY,
  PATHFIX_URL: process.env.REACT_APP_PATHFIX_URL,
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  // TODO: Below mentioned DeepLink is related to Production. Yet to update for staging and development
  DEEPLINK:
    'https://qkbl.adj.st/screen=dashboard?adj_t=hnm2p1g&adj_fallback=https%3A%2F%2Fapp-staging.sparkbox.io%2F&adj_redirect_macos=https%3A%2F%2Fapp-staging.sparkbox.io%2F',
  AWS_REGION: 'us-west-2',
  AWS_HTTP_OPTIONS_TIMEOUT: 600000,
  ADJUST_KEY: process.env.REACT_APP_ADJUST_KEY,
  ADJUST_ENVIRONMENT: process.env.REACT_APP_ADJUST_ENVIRONMENT,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  REACT_APP_LANDING_PAGE_URL: process.env.REACT_APP_LANDING_PAGE_URL,
  REACT_APP_WEB_APP_URL: process.env.REACT_APP_WEB_APP_URL,
  REACT_APP_IFRAME_URL: process.env.REACT_APP_IFRAME_URL,
  REACT_APP_APPLE_REDIRECTION_URI: process.env.REACT_APP_APPLE_REDIRECTION_URI,
};
