import { all, put, call, takeEvery } from 'redux-saga/effects';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from 'utility/axiosClient';
import actions from './actions';
import homeActions from 'redux/home/actions';
import foldersActions from 'redux/folders/actions';
import activityActions from 'redux/activities/actions';
import {
  makeFolderAppearOnTop,
  checkIsHomeActivity,
  getAllNodesWithoutItems,
  handleException,
  alertView,
  noteItemExceptionHandler,
  handleDuplicationFailure,
  deleteAsyncLoaderVisibility,
} from 'utility/helpers';
import {
  removeAsyncAction,
  retryAsync,
  updateAsyncItemTempId,
} from 'utility/asyncHelper';
import { store } from 'redux/store';
import { asyncFolderCreation } from '../../components/folder/helper';
import boardGroupsActions from '../boardGroups/actions';
import jotsActions from '../jots/actions';
import foldersAction from '../folders/actions';
import commonActions from './actions';
import {
  expandHierarchyLevel,
  pauseTheCurrentShare,
  updateIndexSection,
  updateListViewData,
  updateParentMetaEmptyState,
} from '../../components/boardGroups/helper';
import addBarActions from '../addBar/actions';
import {
  CONTAINER_TYPES,
  DND_MY_FILES_FOLDER_DROP_ON_MY_FILES_CREATION_CONTAINER,
  JOTS_PAGE_CREATE_ACTIONS,
} from '../../components/dnd/constant';
import jotsAlertView from 'components/notification/jotsAlertView';
import myFilesAlertView from 'components/notification/myFilesAlertView';
import capturesAlertView from 'components/notification/capturesAlertView';
import boardGroupActions from '../boardGroups/actions';
import { closeAddBarModalAndDisableLoader } from '../../components/addBar/helpers';
import { createApi, updateApi } from '../../components/shared/constants';
import { handleObjectMoveAfterCreation } from "../../components/dnd/utility";

export function* temporaryAction() {
  try {
  } catch (error) {
    yield put({ type: actions.TEMP0RARY_ACTION_SUCCESS });
  }
}
export function* createNewFolder(params) {
  const {
      id,
      payload: { projectId, tempId, isHomeActivity, content },
    } = params,
    { homeFolderItemsList } = store.getState().Home,
    { folderItemsList } = store.getState().Folder,
    {
      isSharedFromSpark,
      droppedData,
      isNode,
      isFromMoveModal,
      dropAction,
    } = store.getState().commonReducer,
    { createNodeFromDropZone, parentMeta } = store.getState().BoardGroups,
    isDocument = content.type === 'page';
  if ((createNodeFromDropZone || droppedData) && !isDocument) {
    yield put({
      type: boardGroupsActions.CLOUD_AREA_COMMON_LOADER,
      payload: true,
    });
  }
  try {
    const response = yield call(() => postRequest(createApi, content));
    yield put({
      type: commonActions.CREATE_NEW_FOLDER_SUCCESS,
    });
    if (!isFromMoveModal) {
      store.dispatch({
        type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
        module: isHomeActivity
          ? 'homeNewFolderVisibility'
          : 'folderCreateModalVisibility',
        payload: {
          visibility: false,
        },
      });
    }
    if (isDocument || isHomeActivity) {
      store.dispatch({
        type: foldersAction.OPEN_FULL_SCREEN_FOLDER_MODAL,
        payload: true,
      });
    }
    if (isHomeActivity) {
      yield put({
        type: jotsActions.CREATE_JOTS_DOCUMENT_SUCCESS,
        payload: response?.data,
      });
    } else if (
      dropAction !== DND_MY_FILES_FOLDER_DROP_ON_MY_FILES_CREATION_CONTAINER
    ) {
      yield put({
        type: boardGroupsActions.UPDATE_BOARD_DETAILS,
        payload: response?.data,
      });
    }
    if (isNode) {
      expandHierarchyLevel({
        selectedKey: response?.data?.id,
        keyDetails: response?.data,
      });
    }
    handleObjectMoveAfterCreation(response);
    if (isSharedFromSpark) {
      yield put({ type: actions.RESET_NEW_FOLDER_FROM_SPARK_STATE });
    }
    yield call(() => updateAsyncItemTempId(tempId, response?.data?.node_id));
    if (!isFromMoveModal) {
      // openFullScreenFolderModal(
      //     true,
      //     {
      //       isArchive: false,
      //       moreMenu: '',
      //       selectedId: response.data.node_id,
      //       isFromSharedSparks: false,
      //       isHomeActivity,
      //       title: response.data.name,
      //       isFolderArchived: false,
      //     },
      //     isSharedFromSpark ? 'sharedSparks' : 'sparks',
      // );
      yield call(() => asyncFolderCreation(tempId, response?.data));
    }
    yield call(() =>
      updateAsyncItemTempId(
        (checkIsHomeActivity(projectId)
          ? homeFolderItemsList[tempId]
          : folderItemsList[tempId]
        )?.data?.find((data) => data?.type === 'target_zone')?.id,
        response?.data?.items.find((data) => data?.type === 'target_zone')
          ?.id,
      ),
    );
    // yield put({
    //   type: isHomeActivity
    //     ? homeActions.ASYNC_UPDATE_HOME_NODES_LISTS
    //     : foldersActions.ASYNC_UPDATE_NODES_LISTS,
    //   payload: response.data,
    //   tempId,
    // });
    yield put({
      type: activityActions.UPDATE_NODES_LISTS,
      payload: response?.data,
    });
    localStorage.setItem(
      `last-focused-document-${projectId}`,
      response?.data?.node_id,
    );
    yield put({
      type: commonActions.SET_DROP_ACTION,
      payload: {
        dropAction: null,
      },
    });
    yield put({
      type: isHomeActivity
        ? homeActions.SET_SELECTED_HOME_NODE_ID
        : foldersActions.SET_SELECTED_NODE_ID,
      payload: response?.data?.node_id,
    });
    yield put({
      type: isHomeActivity
        ? homeActions.UPDATE_SHOW_HOME_NODE_IN_DOM
        : foldersActions.UPDATE_SHOW_NODE_IN_DOM,
      payload: response?.data?.node_id,
    });
    yield put({
      type: actions.CREATED_FOLDER_NODE_ID,
      createdFolderNodeId: response?.data?.node_id,
      createdFolderTitle: response?.data?.name,
    });
    if (isFromMoveModal) {
      getAllNodesWithoutItems();
    }
    const alertObj = {
      alertText:
        isHomeActivity || JOTS_PAGE_CREATE_ACTIONS.includes(dropAction)
          ? 'create_document_success'
          : content.type === 'page'
          ? 'create_document_success'
          : 'create_folder_success',
      type: 'success',
      delay: 3,
    };
    alertView(alertObj);
    makeFolderAppearOnTop(response?.data?.node_id);
    removeAsyncAction(id);
    window.analytics &&
      window.analytics.track('Folder Created', {
        Platform: 'Web App',
      });
    if(parentMeta?.is_shared || parentMeta?.is_saved) {
      pauseTheCurrentShare();
    }
    if(parentMeta.is_empty) {
      updateParentMetaEmptyState(false);
    }
  } catch (error) {
    yield put({
      type: commonActions.CREATE_NEW_FOLDER_FAILURE,
    });
    retryAsync(id, error);
  }
}

export function* getAllArchivedList() {
  try {
    const response = yield call(() => getRequest('list/archive'));
    yield put({
      type: actions.GET_ALL_ARCHIVED_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.TEMP0RARY_ACTION_SUCCESS });
  }
}

export function* duplicateDocumentItem(params) {
  const {
    id,
    payload: {
      itemId,
      isJotsActivity,
      name,
      description,
    },
  } = params;
  try {
    const response = yield call(() =>
      putRequest(
        `v4/hierarchy/duplicate/data/${params.payload.itemId}`,
        { name, description, type: 'item' },
      ),
    );
    yield put({
      type: isJotsActivity
        ? jotsActions.DUPLICATE_JOTS_DOCUMENT_ITEM_API_SUCCESS
        : boardGroupsActions.DUPLICATE_DOCUMENT_ITEM_API_SUCCESS,
      payload: response.data,
    });
    alertView({
      parentContainer: 'direct',
      alertText: 'item_duplicated_successfully',
      type: 'success',
      delay: 3,
    });
    removeAsyncAction(id);
    window.analytics &&
      window.analytics.track('Document Item Duplicated', {
        Platform: 'Web App',
      });
  } catch (error) {
    if (error.response.status === 422) {
      handleDuplicationFailure({
        duplicationProgressId: itemId,
        errorMessage: error?.response?.data?.error?.message,
      });
    }
    retryAsync(id, error);
  }
}

export function* deleteIndividualItem(params) {
  const { id, payload } = params,
    { projectId, deleteItemNodeId, isHomeActivity } = payload;
  try {
    if (deleteItemNodeId) {
      yield call(() =>
        deleteRequest(
          `projects/${projectId}/nodes/${deleteItemNodeId}/items`,
          payload.payload,
        ),
      );
    } else {
      yield call(() =>
        deleteRequest(`projects/${projectId}/items`, payload.payload),
      );
    }
    const alertViewData = {
      alertText: 'delete_item_success',
      type: 'success',
      delay: 4,
    };
    if (isHomeActivity) {
      jotsAlertView(alertViewData);
    } else {
      myFilesAlertView(alertViewData);
    }
    if (params?.payload?.updateList) {
      updateListViewData();
    }
    removeAsyncAction(id);
    window.analytics &&
      window.analytics.track('Item Deleted', {
        Platform: 'Web App',
      });
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* deleteDocumentItem(params) {
  const { id, payload } = params,
    { projectId, nodeId } = payload;
  try {
    yield call(() =>
      deleteRequest(
        `projects/${projectId}/nodes/${nodeId}/items`,
        payload.payload,
      ),
    );
    alertView({
      alertText: 'delete_item_success',
      type: 'success',
      delay: 4,
    });
    removeAsyncAction(id);
    window.analytics &&
      window.analytics.track('Item Deleted', {
        Platform: 'Web App',
      });
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* moveNodeItemToProject(params) {
  const {
    id,
    payload: {
      details: { sourceProjectId, sourceNodeId },
      payload,
      overIsHomeActivity,
      extractName,
      dropOnIndexSection,
    },
  } = params;
  try {
    yield call(() =>
      putRequest(
        `projects/${sourceProjectId}/move/items_and_nodes/${sourceNodeId}`,
        payload,
      ),
    );
    const alertObj = {
      alertText: overIsHomeActivity
        ? 'move_item_to_jots_success'
        : 'move_item_to_board_success',
      type: 'success',
      delay: 3,
      extractName,
    };

    if (dropOnIndexSection) {
      const getStructureResponse = yield call(() =>
        getRequest('v4/hierarchy/get_structure'),
      );
      yield put({
        type: boardGroupActions.GET_ALL_GROUPS_LIST_SUCCESS,
        payload: getStructureResponse.data.groups,
      });
      expandHierarchyLevel({ selectedKey: payload?.target_id });
    }

    if (overIsHomeActivity) {
      jotsAlertView(alertObj);
    } else {
      myFilesAlertView(alertObj);
    }
    updateListViewData();
    removeAsyncAction(id);
    window.analytics &&
      window.analytics.track('Moved Folder to Board', {
        Platform: 'Web App',
      });
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* moveProjectItemToNode(params) {
  const {
    id,
    payload: {
      details: { projectId },
      payload,
      dropOnIndexSection,
      dropZoneCreationFlow,
      isDocumentFlow,
      overDocument,
      extractName,
      from,
    },
  } = params;
  try {
    yield call(() =>
      putRequest(`projects/${projectId}/move/items_and_nodes`, payload),
    );
    updateListViewData();
    if ((dropZoneCreationFlow && !isDocumentFlow) || dropOnIndexSection) {
      const getStructureResponse = yield call(() =>
        getRequest('v4/hierarchy/get_structure'),
      );
      yield put({
        type: boardGroupActions.GET_ALL_GROUPS_LIST_SUCCESS,
        payload: getStructureResponse.data.groups,
      });
    }
    if (dropOnIndexSection) {
      expandHierarchyLevel({ selectedKey: payload.target_id });
    }

    const alertObj = {
      alertText:
        overDocument || isDocumentFlow
          ? 'move_item_to_document_success'
          : 'move_item_to_folder_success',
      type: 'success',
      delay: 3,
      extractName,
    };
    if (from === CONTAINER_TYPES.JOTS) {
      jotsAlertView(alertObj);
    } else {
      myFilesAlertView(alertObj);
    }
    removeAsyncAction(id);
    window.analytics &&
      window.analytics.track('Moved Board Item to Folder', {
        Platform: 'Web App',
      });
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* moveProjectItemToProject(params) {
  const {
    id,
    payload: {
      details: { projectId },
      payload,
      dropOnIndexSection,
      overIsHomeActivity,
      extractName,
    },
  } = params;
  try {
    yield call(() =>
      putRequest(`projects/${projectId}/move/items_and_nodes`, payload),
    );
    const alertObj = {
      alertText: overIsHomeActivity
        ? 'move_item_to_jots_success'
        : 'move_item_to_board_success',
      type: 'success',
      delay: 3,
      extractName,
    };
    if (overIsHomeActivity) {
      jotsAlertView(alertObj);
    } else {
      myFilesAlertView(alertObj);
    }
    updateListViewData();
    if (dropOnIndexSection) {
      expandHierarchyLevel({ selectedKey: payload.project_id });
    }
    removeAsyncAction(id);
    window.analytics &&
      window.analytics.track('Moved Item to Folder from another Folder', {
        Platform: 'Web App',
      });
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* updateFreeSpaceCoordinates(params) {
  const { payload, isTypeItem, objectId, isUrl } = params;
  try {
    yield call(() =>
      putRequest(`v4/hierarchy/update/data/${objectId}`, payload),
    );
    if (isTypeItem && isUrl) {
      yield put({
        type: commonActions.URL_PREVENT_DEFAULT,
      });
    }
  } catch (error) {
    handleException(error);
  }
}

export function* updateBoardItem(params) {
  const {
    id,
    payload: { projectId, itemId, payload },
  } = params;
  try {
    yield call(() =>
      putRequest(`projects/${projectId}/items/${itemId}/update`, payload),
    );
    closeAddBarModalAndDisableLoader({ addBarType: payload.item_type});
    if (payload.type === 'note') {
      yield put({
        type: addBarActions.SET_NOTE_UPDATE_LOADER,
        payload: false,
        isCloseIconDisable: false,
      });
    }
    removeAsyncAction(id);
    window.analytics &&
      window.analytics.track('Board Item Updated', {
        Platform: 'Web App',
      });
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* fetchShareURL(params) {
  const { itemId } = params;
  try {
    const response = yield call(() =>
      postRequest(`v4/hierarchy/${itemId}/sharing`, { isItem: true }),
    );
    yield put({
      type: commonActions.FETCH_SHARE_URL_SUCCESS,
      payload: response?.data?.url,
    });
  } catch (error) {
    yield put({
      type: commonActions.FETCH_SHARE_URL_FAILURE,
    });
    handleException(error);
  }
}

export function* reorderProjectItem(params) {
  const { id, payload } = params,
    { projectId, details } = payload;
  try {
    yield call(() =>
      postRequest(`projects/${projectId}/data/reorder`, details),
    );
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* reorderFolderItem(params) {
  const { id, payload } = params,
    { projectId, nodeId, details } = payload;
  try {
    yield call(() =>
      postRequest(`projects/${projectId}/data/reorder/${nodeId}`, details),
    );
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* getDocumentDetails(params) {
  try {
    const response = yield call(() =>
      getRequest(`projects/${params.projectId}/data/list/${params.nodeId}`),
    );
    yield put({
      type: commonActions.GET_DOCUMENT_DETAILS_SUCCESS,
      payload: response.data.data?.items,
      projectId: response.data.parent_meta.project_id,
      nodeId: response.data.parent_meta.node_id,
      parentMeta: response.data.parent_meta,
    });
  } catch (error) {
    handleException(error);
  }
}

export function* listViewFullScreenDuplicateItem(params) {
  const {
    id,
    payload: { projectId, tempId, nodeId, itemId, name, description },
  } = params;
  try {
    const response = yield call(() =>
      postRequest(
        `projects/${projectId}/nodes/${nodeId}/items/${itemId}/duplicate`,
        { name, description },
      ),
    );
    yield put({
      type: commonActions.DUPLICATE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_SUCCESS,
      payload: response.data?.items?.[0],
      tempId,
    });
    yield call(() => updateAsyncItemTempId(tempId, response?.data?.id));
    removeAsyncAction(id);
    window.analytics &&
      window.analytics.track('Document Item Duplicated', {
        Platform: 'Web App',
      });
  } catch (error) {
    if (error.response.status === 422) {
      handleDuplicationFailure({
        duplicationProgressId: itemId,
        errorMessage: error?.response?.data?.error?.message,
      });
    }
    retryAsync(id, error);
  }
}

export function* listViewFullScreenDeleteItem(params) {
  const { id, payload } = params;
  const { documentProjectId, documentNodeId } = store.getState().commonReducer;
  try {
    yield call(() =>
      deleteRequest(
        `projects/${documentProjectId || payload?.projectId}/nodes/${
          documentNodeId || payload?.nodeId
        }/items`,
        payload.payload,
      ),
    );
    removeAsyncAction(id);
    window.analytics &&
      window.analytics.track('Item Deleted', {
        Platform: 'Web App',
      });
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* updateBoardItemProperties(params) {
  const {
    payload: { captureType, itemId, payload, projectId },
    id,
  } = params;
  try {
    yield call(() =>
      putRequest(`projects/${projectId}/items/${itemId}/update`, payload),
    );
    if (params.payload?.updateList) {
      updateListViewData();
    }
    if (id) {
      removeAsyncAction(id);
    }
    if (captureType === 'note') {
      yield put({
        type: addBarActions.SET_NOTE_UPDATE_LOADER,
        payload: false,
        isCloseIconDisable: false,
      });
    }
    if (captureType !== 'note') {
      alertView({
        parentContainer: 'direct',
        alertText: 'item_updated',
        type: 'success',
        delay: 3,
      });
    }
  } catch (error) {
    if (captureType === 'note') {
      noteItemExceptionHandler();
    }
    if (id) {
      retryAsync(id, error);
    } else {
      handleException(error, params);
    }
  }
}

export function* updateDocumentItemProperties(params) {
  const {
    payload: { captureType, itemId, payload, projectId, nodeId },
    id,
  } = params;
  try {
    if (captureType !== 'note') {
      alertView({
        parentContainer: 'direct',
        alertText: 'item_updated',
        type: 'success',
        delay: 3,
      });
    }
    yield call(() =>
      putRequest(
        `projects/${projectId}/nodes/${nodeId}/items/${itemId}/update`,
        payload,
      ),
    );
    closeAddBarModalAndDisableLoader({ addBarType: payload.item_type });
    if (params.payload?.updateList) {
      updateListViewData();
    }
    if (id) {
      removeAsyncAction(id);
    }
    if (captureType === 'note') {
      yield put({
        type: addBarActions.SET_NOTE_UPDATE_LOADER,
        payload: false,
        isCloseIconDisable: false,
      });
    }
  } catch (error) {
    if (captureType === 'note') {
      noteItemExceptionHandler();
    }
    if (id) {
      retryAsync(id, error);
    } else {
      handleException(error, params);
    }
  }
}
export function* archivePage(params) {
  try {
    yield call(() =>
      putRequest(
        `${updateApi}/${params.payload.id}`,params.payload.payload
      ),
    );
    alertView({
      parentContainer: params.payload.alertContainer,
      alertText: 'document_archived_success',
      type: 'success',
      delay: 3,
    });
    removeAsyncAction(params.id);
  } catch (error) {
    retryAsync(params.id, error);
  }
}
export function* unArchivePage(params) {
  try {
    yield call(() =>
      putRequest(`${updateApi}/${params.payload.id}`, params.payload.payload),
    );
    alertView({
      parentContainer: params.payload.alertContainer,
      alertText:
        params.payload.objectType === 'folder'
          ? 'folder_unarchived_success'
          : 'document_unarchived_success',
      type: 'success',
      delay: 3,
    });
    removeAsyncAction(params.id);
  } catch (error) {
    retryAsync(params.id, error);
  }
}

export function* deleteMultiSelect(params) {
  try {
    if (params?.payload?.folderIds.length > 0) {
      params.payload.folderIds.forEach((id) =>
        updateIndexSection({ deletedId: id }),
      );
    }
    yield call(() =>
      deleteRequest(
        `projects/${params.payload.projectId}/delete/nodes_and_items`,
        params.payload.payload,
      ),
    );
    const alertViewData = {
      alertText: 'delete_item_success',
      type: 'success',
      delay: 4,
    };
    if (params?.payload?.isHomeActivity) {
      jotsAlertView(alertViewData);
    } else {
      myFilesAlertView(alertViewData);
    }
    removeAsyncAction(params.id);
  } catch (error) {
    retryAsync(params.id, error);
  }
}

export function* deleteObjects(params) {
const parentMeta = store.getState().BoardGroups.parentMeta;
  try {
    window.onbeforeunload = () => 'Wait';
    // Below logic is to redirect to parent node after deleting the same node via breadcrumb
    if(params.parentId && parentMeta?.parent_id === params.parentId) {
      expandHierarchyLevel({ selectedKey: params.parentId });
    }
    yield call(() =>
        deleteRequest(
            params.parentId !== 'groups' ? `v4/hierarchy/delete/data/${params.parentId}` : 'v4/hierarchy/delete/data',
            params.payload,
        ),
    );
    params.callback();
  } catch (error) {
    deleteAsyncLoaderVisibility(false);
    handleException(error, params);
  }
}
export function* moveApi(params) {
  const {
    id,
    payload: {
      payload,
      parentId,
      extractName,
      overDocument,
      targetContainer,
      isOverIndexSection,
      initiateGetAllGroupsList,
    },
  } = params;
  try {
    yield call(() => putRequest(`v4/hierarchy/move/data/${parentId}`, payload));
    if (isOverIndexSection || initiateGetAllGroupsList) {
      const getStructureResponse = yield call(() =>
        getRequest('v4/hierarchy/get_structure'),
      );
      yield put({
        type: boardGroupActions.GET_ALL_GROUPS_LIST_SUCCESS,
        payload: getStructureResponse.data.categories,
      });
      if(payload.target_id) {
        expandHierarchyLevel({ selectedKey: payload.target_id });
      }
    }

    if (targetContainer === CONTAINER_TYPES.JOTS) {
      jotsAlertView({
        alertText: overDocument
          ? 'move_item_to_document_success'
          : 'move_item_to_jots_success',
        type: 'success',
        delay: 3,
        extractName,
      });
    } else {
      if (overDocument) {
        myFilesAlertView({
          alertText: 'move_item_to_document_success',
          type: 'success',
          delay: 3,
          extractName,
        });
      } else {
        if(params?.payload?.payload?.has_root_update && parentId){
          myFilesAlertView( {
            alertText: 'folder_successfully_moved',
            type: 'success',
            delay: 3,
          });
        }
        else if(params?.payload?.payload?.has_root_update && !parentId){
          myFilesAlertView({
            alertText: 'category_successfully_moved',
            type: 'success',
            delay: 3,
          });
        }
        else{
          myFilesAlertView({
            alertText: 'move_item_to_folder_success',
            type: 'success',
            delay: 3,
            extractName,
          });
        }
      }
    }
    if(params?.payload?.payload?.has_root_update) {
      if(params.payload.isOverIndexSection && params.payload.parentId) {
        if(!localStorage.getItem('folder_to_category_alert')) {
          yield put({
            type: boardGroupActions.DND_CONFIRMATION_ALERT_VISIBILITY,
            payload: true,
            dndType: 'folder_to_category'
          });
        }
      } else {
        if(!localStorage.getItem('category_to_folder_alert')) {
          yield put({
            type: boardGroupActions.DND_CONFIRMATION_ALERT_VISIBILITY,
            payload: true,
            dndType: 'category_to_folder'
          });
        }
      }
    }
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* moveCaptureItemToOthers(params) {
  const {
    id,
    payload: {
      payload,
      extractName,
      overDocument,
      targetContainer,
      isOverIndexSection,
    },
  } = params;
  try {
    yield call(() => putRequest('v4/hierarchy/move/data', payload));
    if (isOverIndexSection) {
      const getStructureResponse = yield call(() =>
        getRequest('v4/hierarchy/get_structure'),
      );
      yield put({
        type: boardGroupActions.GET_ALL_GROUPS_LIST_SUCCESS,
        payload: getStructureResponse.data.categories,
      });
      expandHierarchyLevel({ selectedKey: payload.target_id });
    }
    if (targetContainer === CONTAINER_TYPES.MY_FILES) {
      myFilesAlertView({
        alertText: overDocument
          ? 'move_item_to_document_success'
          : 'move_item_to_folder_success',
        type: 'success',
        delay: 3,
        extractName,
      });
    } else {
      jotsAlertView({
        alertText: overDocument
          ? 'move_item_to_document_success'
          : 'move_item_to_jots_success',
        type: 'success',
        delay: 3,
        extractName,
      });
    }
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}

export function* moveItemsToCapture(params) {
  const {
    id,
    payload: { payload, parentId },
  } = params;
  try {
    yield call(() => putRequest(`v4/hierarchy/move/data/${parentId}`, payload));
    capturesAlertView({
      alertText: 'items_moved_successfully',
      type: 'success',
      delay: 3,
    });
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}
export function* reorderItems(params) {
  const {
    id,
    payload: { objectId, payload },
  } = params;
  try {
    yield call(() =>
      putRequest(`v4/hierarchy/reorder/data/${objectId}`, payload),
    );
    removeAsyncAction(id);
  } catch (error) {
    retryAsync(id, error);
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.TEMP0RARY_ACTION, temporaryAction),
    takeEvery(actions.CREATE_NEW_FOLDER, createNewFolder),
    takeEvery(actions.GET_ALL_ARCHIVED_LIST, getAllArchivedList),
    takeEvery(actions.DUPLICATE_DOCUMENT_ITEM_API, duplicateDocumentItem),
    takeEvery(actions.INDIVIDUAL_ITEM_DELETE_API, deleteIndividualItem),
    takeEvery(actions.ASYNC_MOVE_NODE_ITEM_TO_PROJECT, moveNodeItemToProject),
    takeEvery(actions.ASYNC_MOVE_PROJECT_ITEM_TO_NODE, moveProjectItemToNode),
    takeEvery(
      actions.ASYNC_MOVE_PROJECT_ITEM_TO_PROJECT,
      moveProjectItemToProject,
    ),
    takeEvery(actions.UPDATE_BOARD_ITEM, updateBoardItem),
    takeEvery(actions.DOCUMENT_ITEM_DELETE_API, deleteDocumentItem),
    takeEvery(actions.FETCH_SHARE_URL, fetchShareURL),
    takeEvery(actions.ASYNC_REORDER_PROJECT_ITEM, reorderProjectItem),
    takeEvery(actions.ASYNC_REORDER_FOLDER_ITEM, reorderFolderItem),
    takeEvery(actions.GET_DOCUMENT_DETAILS, getDocumentDetails),
    takeEvery(
      actions.DUPLICATE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM,
      listViewFullScreenDuplicateItem,
    ),
    takeEvery(
      actions.DELETE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_API,
      listViewFullScreenDeleteItem,
    ),
    takeEvery(actions.UPDATE_BOARD_ITEM_PROPERTIES, updateBoardItemProperties),
    takeEvery(
      actions.UPDATE_DOCUMENT_ITEM_PROPERTIES,
      updateDocumentItemProperties,
    ),
    takeEvery(actions.ARCHIVE_PAGE, archivePage),
    takeEvery(actions.UN_ARCHIVE_PAGE, unArchivePage),
    takeEvery(actions.DELETE_MULTI_SELECT, deleteMultiSelect),
    takeEvery(actions.DELETE_OBJECT, deleteObjects),
    takeEvery(actions.ASYNC_MOVE_API, moveApi),
    takeEvery(
      actions.ASYNC_MOVE_CAPTURE_ITEM_TO_OTHERS,
      moveCaptureItemToOthers,
    ),
    takeEvery(actions.ASYNC_MOVE_ITEMS_TO_CAPTURE, moveItemsToCapture),
    takeEvery(actions.ASYNC_REORDER, reorderItems),
    takeEvery(actions.FREE_SPACE_API_LATEST, updateFreeSpaceCoordinates),
  ]);
}
